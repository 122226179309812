import http from "../../services/http";

import {
    setClientsCurrentPage,
    setClientsFirstPage,
    setClientsLastPage,
    setClientsList,
    setClientsListError,
    setClientsNextPage,
    setClientsPayments,
    setClientsPrevPage,
    setLoadingClientsList,
    setLoadingSingleClient,
    setSingleClient,
    setSingleClientError,
    setTotalClients,
    setRandomPassword,
    setNewCustomerEmailAddress,
    setClientCommentsData,
    setClientCommentsError,
    setClientCommentsLoading,
    setMeiliClientsError,
    setMeiliClients,
    setMeiliTotalClients,
    setMeiliTotalClientPages,
    setMeiliClientsCurrentPage,
    setMeiliClientsFirstPage,
    setMeiliClientsLastPage,
    setMeiliClientsNextPage,
    setMeiliClientsPreviousPage,
    setSendWelcomeEmailError,
    setSendWelcomeEmailLoading,
    setSendWelcomeEmailSuccess,
    setRefundError
}                        from "./clients.actions";
import meiliSearchClient from "../../services/MeiliSearchClient";

export const getClientsList = (queryParams) => {
    return async dispatch => {
        dispatch(setLoadingClientsList(true));
        dispatch(setClientsListError(null));

        try {
            const clients = await http.get(
                `orders?sort=-priority,createdAt`,
                {
                    params: queryParams
                }
            );

            const data      = clients.data;
            const hydraView = data?.["hydra:view"];
            dispatch(setClientsCurrentPage(hydraView?.["@id"] ?? null));
            dispatch(setClientsFirstPage(hydraView?.["hydra:first"] ?? null));
            dispatch(setClientsLastPage(hydraView?.["hydra:last"] ?? null));
            dispatch(setClientsPrevPage(hydraView?.["hydra:previous"] ?? null));
            dispatch(setClientsNextPage(hydraView?.["hydra:next"] ?? null));

            dispatch(setClientsList(data["hydra:member"]));
            dispatch(setTotalClients(data["hydra:member"].length));
        } catch (e) {
            dispatch(setClientsList([]));
            dispatch(setTotalClients(0));
            dispatch(setClientsListError(e.message));
        } finally {
            dispatch(setLoadingClientsList(false));
        }
    };
};

export const getSingleClient = (client_uuid) => {
    return async dispatch => {
        dispatch(setLoadingSingleClient(true));

        try {
            const clientData = await http.get(
                `orders/${client_uuid}`
            );

            dispatch(setSingleClient(clientData.data));
        } catch (e) {
            dispatch(setSingleClient(null));
            dispatch(setSingleClientError(e.message));
        } finally {
            dispatch(setLoadingSingleClient(false));
        }
    };
};

export const unsubscribeClient = (client_uuid) => {
    return async dispatch => {
        dispatch(setSingleClientError(null));

        try {
            await http.put(
                `customers/${client_uuid}/unsubscribe`,
                {}
            );
        } catch (e) {
            dispatch(setSingleClientError('error_unsubscribe'));
        } finally {
            setTimeout(() => {
                dispatch(setSingleClientError(null));
            }, 20000);
        }
    };
};

export const getOrderPayments = order_uuid => {
    return async dispatch => {
        dispatch(setLoadingClientsList(true));

        try {
            const payments = await http.get(`orders/${order_uuid}/payments`);

            const data = payments.data;

            data.payments = data
                .payments
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

            dispatch(setClientsPayments(data.payments));
        } catch (e) {
        } finally {
            dispatch(setLoadingClientsList(false));
        }
    };
};

export const refundPayments = payment_ids => {
    return async dispatch => {
        dispatch(setRefundError(null));
        try {
            const payments = await http
                .post(
                    `payments/refund`,
                    {
                        payments: payment_ids
                    }
                );

            if (payments.data.not_refunded && payments.data.not_refunded.length > 0) {
                dispatch(setRefundError("error_refund"));
            }
            return payments.data;
        } catch (e) {
        } finally {
            setTimeout(() => {
                dispatch(setRefundError(null));
            }, 3000);
        }
    };
};

export const getRandomPassword = (client_uuid) => {
    return async dispatch => {
        try {
            const randomPassword = await http.get(`customers/${client_uuid}/random-password/users`);

            dispatch(setRandomPassword(randomPassword?.data));
        } catch (e) {
            console.dir(e);
        }
    };
};

export const setNewEmailAddress = (client_uuid, email_address) => {
    return async dispatch => {
        try {
            const response = await http.put(
                `customers/${client_uuid}/replace-email`,
                {
                    email: email_address
                }
            );

            dispatch(setNewCustomerEmailAddress(response.data.email));

            return "";
        } catch (e) {
            return e?.response?.data?.["hydra:description"] ?? e?.message ?? "Something went wrong";
        }
    };
};

export const getSingleOrderComments = (order_uuid, queryParams) => {
    return async dispatch => {
        dispatch(setClientCommentsLoading(true));
        dispatch(setClientCommentsError(null));

        try {
            const comments = await http.get(
                `orders/${order_uuid}/comments`,
                {
                    params: queryParams
                }
            );

            dispatch(setClientCommentsData(comments.data["hydra:member"]));
        } catch (e) {
            dispatch(setClientCommentsData([]));
            dispatch(setClientCommentsError(e.message));
        } finally {
            dispatch(setClientCommentsLoading(false));
        }
    };
};

export const meiliSearchClients = options => {
    return async dispatch => {
        dispatch(setMeiliClientsError(null));
        dispatch(setMeiliClients([]));
        dispatch(setMeiliTotalClients(0));
        dispatch(setMeiliTotalClientPages(1));
        dispatch(setMeiliClientsCurrentPage(1));
        dispatch(setMeiliClientsFirstPage(1));
        dispatch(setMeiliClientsLastPage(1));
        dispatch(setMeiliClientsNextPage(1));
        dispatch(setMeiliClientsPreviousPage(1));

        try {
            const defaultOptions = {
                sort       : [
                    "order_created_at:asc"
                ],
                hitsPerPage: 20
            };
            const response       = await meiliSearchClient.post(
                `/indexes/${meiliSearchClient.defaults.prefix}orders/search`,
                {
                    ...defaultOptions,
                    ...options
                },
                {
                    "headers": {}
                }
            );

            dispatch(setMeiliClients(response?.data?.hits ?? []));
            dispatch(setMeiliTotalClients(response?.data?.totalHits ?? 0));
            dispatch(setMeiliTotalClientPages(response?.data?.totalPages ?? 0));
            dispatch(setMeiliClientsCurrentPage(response?.data?.page ?? 0));
            dispatch(setMeiliClientsFirstPage(1));
            dispatch(setMeiliClientsLastPage(response?.data?.totalPages ?? 0));
            dispatch(setMeiliClientsNextPage(response.data.totalPages > response.data.page ? response?.data?.page + 1 : response?.data?.totalPages));
            dispatch(setMeiliClientsPreviousPage(1 < response.data.page ? response?.data?.page - 1 : 1));
        } catch (e) {
            console.log(e);
        } finally {

        }
    };
};

export const sendBackWelcomeEmail = (client_uuid) => {
    return async dispatch => {
        dispatch(setSendWelcomeEmailError(null));
        dispatch(setSendWelcomeEmailLoading(true));

        try {
            await http.post(
                `customers/${client_uuid}/send-back-confirmation-mail`,
                {}
            );
            
            dispatch(setSendWelcomeEmailError(null));
            dispatch(setSendWelcomeEmailSuccess(true));
        } catch (e) {
            dispatch(setSendWelcomeEmailError(e.message));
        } finally {
            setTimeout(() => {
                dispatch(setSendWelcomeEmailLoading(false));
                dispatch(setSendWelcomeEmailSuccess(false));
            }, 3000);
        }
    };
}


