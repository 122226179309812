import React                    from "react";
import Navigation               from "../../components/Navigation/Navigation";
import {
    Badge,
    Breadcrumb,
    Col,
    Row,
    Table,
    Alert,
    Button,
    Dropdown,
    ButtonGroup
}                               from "react-bootstrap";
import Footer                   from "../Footer/Footer";
import Loader                   from "../../components/Common/Loader";
import {connect}                from "react-redux";
import {Link, withRouter}       from "react-router-dom";
import {getSingleClient}        from "../../redux/Clients/clients.async-actions";
import {
    getSinglePurchase,
    getSinglePurchaseComments,
    requestMissingInformation,
    setPurchaseDrivingLicensePoints,
    setStatusToCancelled,
    setStatusToCompleted,
    setStatusToOnHold
}                               from "../../redux/Purchases/purchases.async-actions";
import {
    setConfirmationComments, setIsFilePicked, setShowUploadDocumentModal,
    setSinglePurchase
}                               from "../../redux/Purchases/purchases.actions";
import routerConstants          from "../../constants/router-constants";
import DateUtils                from "../../services/DateUtils";
import UUIDs                    from "../../services/UUIDs";
import provinces                from "../../constants/provinces";
import CrifDataComponent        from "../../components/Common/CrifDataComponent";
import CopyDatabutton           from "../../components/Common/CopyDataButton";
import {
    postComment,
    deleteComment,
    updateComment
}                               from "../../redux/Comments/comments.async-actions";
import {
    useFieldArray,
    useForm
}                               from "react-hook-form";
import {Trash}                  from "react-feather";
import ConfirmDeletComments     from "./Modals/ConfirmDeleteCommentsModal";
import ConfirmStatusChangeModal from "./Modals/ConfirmStatusChangeModal";
import UploadDocumentModal      from "./Modals/UploadDocumentModal";
import store                    from "../../redux/store";
import {
    getEmailTemplatesList
}                               from "../../redux/EmailTemplate/emailTemplate.async-actions";

class ListSinglePurchase extends React.Component {
    state = {
        sideMenu                : true,
        commentsElement         : [],
        newCommentLimiter       : false,
        commentAdded            : true,
        commentsId              : "",
        commentsIndex           : 0,
        showCommentsModal       : false,
        processingCommentsDelete: false,
        showStatusChangeModal   : false,

        purchaseId                  : null,
        currentStatusText           : "",
        nextStatusText              : "",
        customerEmail               : "",
        customerLastName            : "",
        customerFirstName           : "",
        operation                   : "",
        nameOfService               : "",
        drivingLicensePoints        : 0,
        proDrivingLicensePoints     : null,
        missingInformation          : false,
        uploading                   : false,
        hideDownloadModal           : false,
        purchase                    : null,
        selectedFieldsIds           : [],
        emailTemplateData           : {},
        emailTemplate               : [],
        drivingLicensePointsNotValid: false,
        showBulkActionModalState    : false,
        purchaseStatusModal         : ""
    };

    constructor(props) {
        super(props);

        this.showChangeStatusModalCallback      = this.showChangeStatusModal.bind(this);
        this.hideChangeStatusModalCallback      = this.hideChangeStatusModal.bind(this);
        this.updateDrivingLicensePointsCallback = this.updateDrivingLicensePointsMethod.bind(this);
        this.setStatusToCancelledMethod         = this.updatePurchaseStatus.bind(this);
        this.goToRouteMethod                    = this.goToRoute.bind(this);
    }

    // Loading icon false after DOM loaded
    async componentDidMount() {
        await this.getSinglePurchaseFunction();
        await this.getSinglePurchaseComments();

        if (this?.props?.emailTemplate?.length === 0) {
            await this.getEmailTemplateNotDeliveredService();
        }

        this.loadEmailTemplateSelect();

        if (null === this.props.commentsError) {
            this.setState({
                              commentsElement: this?.props?.purchaseComments?.map((comment) => {
                                  return {
                                      id         : comment.id,
                                      commentText: comment.commentText,
                                      createdAt  : comment.createdAt,
                                      userEmail  : comment.userEmail
                                  };
                              })
                          });
        }
    }

    async getSinglePurchaseComments(queryParams = {}) {
        queryParams = {
            "order[createdAt]": "desc"
        };

        await this.props.getSinglePurchaseComments(this.props.match.params.purchase_uuid, queryParams);

    }

    getSinglePurchaseFunction = async () => {
        await this.props.getSinglePurchase(this.props.match.params.purchase_uuid);
    };

    componentWillUnmount() {
        this.props.setSinglePurchase(null);
    }

    // Toggle side bar menu
    _onSideMenu = active => {
        this.setState({sideMenu: active});
    };

    showConfirmDeleteCommentsModal = (
        commentsId,
        userName,
        commentsIndex
    ) => {
        this.setState(
            {
                showCommentsModal: true,
                commentsId,
                userName,
                commentsIndex
            }
        );
    };

    hideConfirmDeleteCommentsModal = () => {
        this.setState(
            {
                showCommentsModal: false,
                commentsId       : null,
                userName         : ""
            }
        );
    };

    /**
     * Set the drivingLicensePoints state value to the provided value number.
     *
     * @param drivingLicensePoints
     */
    updateDrivingLicensePointsMethod(drivingLicensePoints = 0, proDrivingLicensePoints = null) {
        this
            .setState(
                {
                    drivingLicensePoints   : parseInt(drivingLicensePoints, 10),
                    proDrivingLicensePoints: null === proDrivingLicensePoints ? null : parseInt(
                        proDrivingLicensePoints,
                        10
                    )
                }
            );
    }

    /**
     * Loading the values related to the available email templates.
     */
    loadEmailTemplateSelect = () => {
        if (null === this?.props?.errorEmailTempltate) {
            this.emailTemplatesOptions = this
                ?.props
                ?.emailTemplate
                ?.map(
                    emailTemplate => {
                        return {
                            value: UUIDs.getEntityUUID(emailTemplate["@id"]),
                            label: emailTemplate.description
                        };
                    }
                ) ?? {};

            const noSendEmail = {
                value: "",
                label: "NO EMAIL"
            };

            const updateOptions = [
                noSendEmail,
                ...this.emailTemplatesOptions
            ];

            this.setState(
                {
                    emailTemplate: updateOptions
                }
            );
        }
    };

    /**
     * Redirect user to provided route.
     *
     * @param route
     * @param e
     */
    goToRoute(route, e) {
        e.stopPropagation();
        e.preventDefault();

        this
            .props
            .history
            .push(route);
    }

    renderInfo() {
        if (this.props.singlePurchase) {
            const client      = this.props.singlePurchase.orders[0].customer;
            const lastName    = client.lastName;
            const firstName   = client.firstName;
            const phoneNumber = client.phoneNumber;

            return (
                <dl>
                    <dt>first name :</dt>
                    <dl>{firstName}</dl>
                    <dt>last name :</dt>
                    <dd>{lastName}</dd>
                    <dt>phone number:</dt>
                    <dd>{phoneNumber}</dd>
                </dl>
            );
        }
    }

    /**
     * Update the emailTemplateData state to the new value
     *
     * @param emailTemplate
     */
    onEmailTemplateChange = emailTemplate => this.setState({emailTemplateData: emailTemplate});


    clientFullName() {
        if (this.props.singlePurchase) {
            const client    = this.props.singlePurchase.orders[0].customer;
            const lastName  = client.lastName;
            const firstName = client.firstName;

            if (0 === lastName.length) {
                return (
                    <em>
                        <small>
                            Not Available
                        </small>
                    </em>
                );
            }

            return `${lastName} ${firstName}`;
        }

        return "loading...";
    }

    getCustomerData() {
        const customerData                  = this?.props?.singlePurchase?.customerData ?? {};
        const purchasesData                 = this?.props?.singlePurchase ?? {};
        const getSendgridPath               = purchasesData
            ?.sendgrid_attachement_path
            ?.replace(/^.*[\\\/]/, "")
            ?.replace("uploads", "");
        const getSendgridPathWithoutId      = getSendgridPath
            ?.split("-")
            ?.pop()
            ?.split(".")
            ?.[0];
        const getSedgridPathWithoutSymboles = getSendgridPath
            ?.replace(getSendgridPathWithoutId, "")
            ?.split("-")
            ?.join("");
        let keyValues                       = [];

        Object
            .keys(customerData)
            .forEach(
                key => {
                    const result = key.replace(/([A-Z])/g, " $1");
                    const label  = result.charAt(0).toUpperCase() + result.slice(1);
                    let value    = customerData[key];

                    if ("object" === typeof value) {
                        for (let propertyName in value) {
                            let sub_label = propertyName.split("_");
                            sub_label     = sub_label.map(lbl => lbl.charAt(0).toUpperCase() + lbl.slice(1)).join(" ");
                            keyValues.push(
                                {
                                    label: `${label}: ${sub_label}`,
                                    value: value[propertyName]
                                }
                            );
                        }
                    }

                    if ("customerData" === key) {
                        return;
                    }

                    if (label === "Province") {
                        value = provinces?.[value] ?? value;
                    }

                    keyValues
                        .push(
                            {
                                label,
                                value
                            }
                        );
                }
            );

        return [...keyValues, {label: "Document", value: getSedgridPathWithoutSymboles}];
    }

    mapPurchaseHistory = () => {
        const purchase        = this?.props?.singlePurchase;
        const purchaseHistory = null !== purchase ? purchase?.purchaseHistories : null;

        if (null === purchase || null === purchaseHistory) {
            return (
                <tr>
                    <td colSpan={4} className="text-center">
                        No Purchases History Entries
                    </td>
                </tr>
            );
        }

        const orderId          = UUIDs.getEntityUUID(purchase.orders[0]["@id"]);
        const customerFileLink = `/list-clients/${orderId}`;

        return purchaseHistory.map(
            entry => {
                let statusBadgeVariant = "primary";
                let statusBadgeText    = "In Progress";

                switch (entry.status) {
                    case "IN_PROGRESS":
                        statusBadgeVariant = "primary";
                        statusBadgeText    = "In Progress";
                        break;
                    case "PENDING":
                        statusBadgeVariant = "dark";
                        statusBadgeText    = "Pending";
                        break;
                    case "COMPLETED":
                        statusBadgeVariant = "success";
                        statusBadgeText    = "Completed";
                        break;
                    case "CANCELLED":
                        statusBadgeVariant = "warning";
                        statusBadgeText    = "Cancelled";
                        break;
                    case "CANCELLED_ORDER":
                        statusBadgeVariant = "danger";
                        statusBadgeText    = "Cancelled Subscription";
                        break;
                    case "ON_HOLD":
                        statusBadgeVariant = "info";
                        statusBadgeText    = "On Hold";
                        break;
                    case "UPLOAD":
                        statusBadgeVariant = "secondary";
                        statusBadgeText    = "Uploaded file";
                        break;
                    case "ASK_INFORMATION":
                        statusBadgeVariant = "info";
                        statusBadgeText    = "Ask Information";
                        break;
                }

                return (
                    <tr key={entry["@id"]}>
                        <td>
                            <Link to={customerFileLink}>
                                {purchase.orders[0].customer.email}
                                <br />
                                <small>{purchase.orders[0].customer.lastName} {purchase.orders[0].customer.firstName}</small>
                            </Link>
                        </td>
                        <td>
                            {DateUtils.formatISO8601ToFormat(entry.createdAt)}
                            &nbsp;<small>({DateUtils.formatISO8601ToHumanReadable(entry.createdAt)})</small>
                        </td>
                        <td>
                            {
                                entry?.user &&
                                `${entry?.user?.firstName ?? ""} ${entry?.user?.lastName ?? ""}<br><small>${entry?.user?.email ?? ""}</small>`
                            }
                            {
                                typeof undefined === typeof entry?.user ? "N/A" : ""
                            }
                        </td>
                        <td>{purchase.service.name}</td>
                        <td>
                            <Badge pill variant={statusBadgeVariant} className="mt-2 mr-2">
                                {statusBadgeText}
                            </Badge>
                        </td>
                    </tr>
                );
            }
        );
    };

    addCommentField = () => {
        this.props.append({newComment: ""});
        this.setState({newCommentLimiter: true});
    };

    removeCommentField = (index) => {
        this.props.remove(index);
        this.setState({newCommentLimiter: false});
    };

    deleteComment = () => {
        let newComments = [...this.state.commentsElement];
        newComments.splice(this.state.commentsIndex, 1);

        this.setState(
            {
                commentsElement: newComments
            }
        );

        this.props.deleteComment(this.state.commentsId);

        this.setState(
            {
                processingCommentsDelete: false,
                showCommentsModal       : false
            }
        );
    };

    /**
     * Display the change status modal dialog.
     * @param purchaseId
     * @param currentStatusText
     * @param nextStatusText
     * @param email
     * @param lastName
     * @param firstName
     * @param operation
     * @param missingInfo
     * @param serviceName
     */
    showChangeStatusModal(
        purchaseId,
        currentStatusText,
        nextStatusText,
        email,
        lastName,
        firstName,
        operation,
        missingInfo = false,
        serviceName = ""
    ) {
        this
            .setState(
                {
                    showStatusChangeModal: true,
                    purchaseId,
                    currentStatusText,
                    nextStatusText,
                    customerEmail        : email,
                    customerLastName     : lastName,
                    customerFirstName    : firstName,
                    operation,
                    missingInformation   : missingInfo,
                    nameOfService        : serviceName
                }
            );
    }

    /**
     * Hide the change status modal dialog and reset the state.
     */
    hideChangeStatusModal() {
        this
            .setState(
                {
                    showStatusChangeModal: false,
                    purchaseId           : null,
                    currentStatusText    : "",
                    nextStatusText       : "",
                    customerEmail        : "",
                    customerLastName     : "",
                    customerFirstName    : "",
                    operation            : ""
                }
            );
    }

    /**
     * Display the upload modal dialog.
     * @param purchaseId
     * @param lastName
     * @param firstName
     * @param purchase
     */
    showUploadDocumentModal(
        purchaseId,
        lastName,
        firstName,
        purchase
    ) {
        store
            .dispatch(
                setShowUploadDocumentModal(true)
            );
        this
            .setState(
                {
                    purchaseId,
                    customerLastName : lastName,
                    customerFirstName: firstName,
                    purchase         : purchase
                }
            );
    }

    /**
     * Hide the upload modal dialog and reset the state.
     */
    hideUploadDocumentModal = () => {
        store
            .dispatch(
                setShowUploadDocumentModal(false)
            );
        store
            .dispatch(
                setIsFilePicked(false)
            );
        this
            .setState(
                {
                    customerLastName : "",
                    customerFirstName: ""
                }
            );
    };

    /**
     * Toggle the comments section in the dialogs.
     */
    showCommentsSection = () => {
        this
            .setState(
                {
                    setShowComments: !this.state.setShowComments
                }
            );
    };

    /**
     * Set the drivingLicensePoints state value to the provided value number.
     *
     * @param drivingLicensePoints
     */
    updateDrivingLicensePointsMethod(drivingLicensePoints = 0, proDrivingLicensePoints = null) {
        this
            .setState(
                {
                    drivingLicensePoints   : parseInt(drivingLicensePoints, 10),
                    proDrivingLicensePoints: null === proDrivingLicensePoints ? null : parseInt(
                        proDrivingLicensePoints,
                        10
                    )
                }
            );
    }

    async updatePurchaseStatus() {
        switch (this.state.operation) {
            case "CANCELLED":
                await this
                    .props
                    .setStatusToCancelled(
                        this.state.purchaseId,
                        this.state.emailTemplateData,
                        this.props.commentsText
                    );
                break;
            case "DELIVERED":
                if (this.state.drivingLicensePoints > 30 || this.state.proDrivingLicensePoints > 30) {
                    this
                        .setState(
                            {
                                drivingLicensePointsNotValid: true,
                                processingStatusChange      : false
                            }
                        );
                    this.hideChangeStatusModalCallback();
                    return;
                }

                if ("driving_licence" === this.state.nameOfService) {
                    await this
                        .props
                        .setPurchaseDrivingLicensePoints(
                            this.props.match.params.purchase_uuid,
                            parseInt(this.state.drivingLicensePoints, 10),
                            null === this.state.proDrivingLicensePoints ? null : parseInt(
                                this.state.proDrivingLicensePoints,
                                10
                            )
                        );
                }

                this.setState(
                    {
                        drivingLicensePointsNotValid: false
                    }
                );
                await this
                    .props
                    .setStatusToCompleted(
                        this.props.match.params.purchase_uuid,
                        this.props.commentsText
                    );
                break;
            case "ON_HOLD":
                await this
                    .props
                    .setStatusToOnHold(
                        this.props.match.params.purchase_uuid,
                        this.state.selectedFieldsIds,
                        this.props.commentsText
                    );
                break;
            case "REQUEST_MISSING_INFO":
                await this
                    .props
                    .requestMissingInformation(
                        this.props.match.params.purchase_uuid,
                        this.state.selectedFieldsIds,
                        this.props.commentsText
                    );
                break;
        }

        this.hideChangeStatusModal();
    }

    /**
     * Responsible to get the email templates from the server
     * @param queryParams
     * @returns {Promise<void>}
     */
    async getEmailTemplateNotDeliveredService(queryParams = {}) {
        await this
            .props
            .getEmailTemplatesList(
                {
                    ...{
                        name: "SENDGRID_NOT_DELIVERED_SERVICE_EMAIL"
                    },
                    ...queryParams
                }
            );
    }

    /**
     * Save in the redux store the confirmation comment.
     *
     * @param e
     */
    handleCommentsText = (e) => {
        store
            .dispatch(
                setConfirmationComments(e.target.value)
            );
    };

    onSubmit = async (data) => {
        if (data.newValue.newComment === "") {
            return;
        }

        let newValue = {};

        if (!data.newValue[0]) {
            newValue = {...data.newValue, userNewEmail: this.props.user.email};
        } else {
            newValue = {...data.newValue[0], userNewEmail: this.props.user.email};
        }

        await this.props.postComment(this.props.match.params.purchase_uuid, newValue);

        if (this.props.commentsSuccess === true) {
            await this.getSinglePurchaseComments(this.props.match.params.purchase_uuid);

            this.setState({
                              commentAdded   : false,
                              commentsElement: this?.props?.purchaseComments?.map((comment) => {
                                  return {
                                      id         : comment.id,
                                      commentText: comment.commentText,
                                      createdAt  : comment.createdAt,
                                      userEmail  : comment.userEmail
                                  };
                              })
                          });
        }
    };

    pageAlerts = () => {
        return <>
            {
                this.props.isCopied === true ?
                    <Alert key={"successCopied"} variant={"success"}>
                        Copied !!
                    </Alert> :
                    null}
            {
                this.props.commentsSuccess === true ?
                    <Alert key={"successComment"} variant={"success"}>
                        Comment added successfully
                    </Alert> :
                    null}
            {
                this.props.commentsError !== null ?
                    <Alert key={"errorComment"} variant={"danger"}>
                        {this.props.commentsError}
                    </Alert> :
                    null}
            {
                this.props.deleteSuccess === true ?
                    <Alert key={"successDelete"} variant={"success"}>
                        Comment deleted successfully
                    </Alert> :
                    null}
            {
                this.props.deleteError !== null ?
                    <Alert key={"errorDelete"} variant={"danger"}>
                        {this.props.deleteError}
                    </Alert> :
                    null}
            {
                this.props.commentsError !== null ?
                    <Alert key={"errorComment"} variant={"danger"}>{
                        this.props.commentsError}
                    </Alert> :
                    null}
            {
                this.props.uploadSuccess === true &&
                this.props.uploadError === null ?
                    <Alert key={"successUpload"} variant={"success"}>
                        Success !! your document has been uploaded.
                    </Alert> :
                    null
            }
            {
                this.props.uploadError !== null ?
                    <Alert key={"dangerUpload"} variant={"danger"}>
                        Upload failed please re try.
                    </Alert> :
                    null
            }
            {
                this.state.drivingLicensePointsNotValid === true ?
                    <Alert key={"dangerPoints"} variant={"danger"}>
                        Please select a number of points under or equal 30.
                    </Alert> :
                    null
            }
            {
                this.props.deleteSuccessDocument === true &&
                this.props.deleteError === null ?
                    <Alert key={"successDelete"} variant={"success"}>
                        Success !! your document has been deleted.
                    </Alert> :
                    null
            }
            {
                this.props.deleteErrorDocument !== null ?
                    <Alert key={"dangerDelete"} variant={"danger"}>
                        delete was failed failed please re try.
                    </Alert> :
                    null
            }
        </>;
    };

    /**
     * Open given route to a new browser tab
     *
     * @param route
     * @param e
     */
    gotToRouteNewPage(route, e) {
        e.stopPropagation();
        e.preventDefault();

        this
            .props
            .history
            .push(
                window.open(route)
            );
    }

    render() {
        let loader             = null;
        let serviceName        = this?.props?.singlePurchase?.service?.name ?? {};
        let serviceSlug        = this?.props?.singlePurchase?.service?.slug ?? {};
        let documentInfo       = this?.props?.singlePurchase?.documentInfo ?? null;
        let statusBadgeVariant = "primary";
        let statusBadgeText    = "In Progress";
        const singlePurchase   = this?.props?.singlePurchase;
        let orderId            = singlePurchase?.orders[0]?.["@id"];
        orderId                = UUIDs.extractApiOrders(orderId);
        const customerFileLink = `/list-clients/${orderId}`;
        let isUploaded         = null !== singlePurchase?.sendgrid_attachement_path && "" !== singlePurchase?.sendgrid_attachement_path ? "success" : "primary";
        let isUploadedOutline  = null !== singlePurchase?.sendgrid_attachement_path && "" !== singlePurchase?.sendgrid_attachement_path ? "outline-success" : "outline-primary";

        if (
            this.props.loadingSingleClient
            || this.props.commentsLoading
            || this.props.deleteLoading
            || this.props.Loading
            || this.props.purchaseCommentsLoading
        ) {
            loader = <Loader message="Loading..." />;
        }

        const getPurchaseData = () => {
            const customerData               = singlePurchase?.customerData ?? {};
            const orderData                  = singlePurchase?.orders[0].customer ?? {};
            const serviceData                = singlePurchase?.service ?? {};
            const sendGridAttachmentPath     = singlePurchase?.sendgrid_attachement_path ?? "";
            const getSendgridAttachementPath = sendGridAttachmentPath.replace(/^.*[\\\/]/, "");
            const mergeCustomerOrderData     = {
                ...customerData,
                ...orderData,
                document: getSendgridAttachementPath
            };

            if (serviceName === "CRIF") {
                return <CrifDataComponent customerOrder={mergeCustomerOrderData} />;
            } else {
                let customerData = this.getCustomerData();

                if (serviceSlug === "generic") {
                    const reorderArray = array => {
                        // Define the desired order of labels
                        const order = [
                            "Type",
                            "Type Details",
                            "Birth Date",
                            "City Of Birth",
                            "Province Of Birth",
                            "Address of Residence",
                            "Province Of Residence",
                            "External Customer Id",
                            "Fiscal Code",
                            "Company Name"
                        ];

                        const excludeLabels = new Set(
                            [
                                "Orders: 0",
                                "Birth Day",
                                "Birth Month",
                                "Birth Year",
                            ]
                        );

                        const orderMap = new Map(
                            order.map(
                                (label, index) => [label, index]
                            )
                        );

                        const birthDay = customerData.find(item => item.label === "Birth Day")?.value;
                        const birthMonth = customerData.find(item => item.label === "Birth Month")?.value;
                        const birthYear = customerData.find(item => item.label === "Birth Year")?.value;

                        if (birthDay && birthMonth && birthYear) {
                            const birthDate = `${birthDay} / ${birthMonth} / ${birthYear}`;
                            customerData.push(
                                {
                                    label: "Birth Date",
                                    value: birthDate
                                }
                            );
                        }

                        customerData.push(
                            {
                                label: "Type",
                                value: serviceData?.slug
                            },
                        );

                        return array
                            .filter(item => !excludeLabels.has(item.label))
                            .slice() // Create a shallow copy of the filtered array
                            .sort((a, b) => {
                                const indexA = orderMap.get(a.label) ?? Infinity;
                                const indexB = orderMap.get(b.label) ?? Infinity;
                                return indexA - indexB;
                            });
                    }
                    customerData = reorderArray(customerData);
                }

                if (serviceSlug === 'telegramma') {
                    const reorderArray = array => {
                        // Define the desired order of labels
                        const order = [
                            "Sender_first Name",
                            "Sender_last Name",
                            "Sender_street Address",
                            "Sender_home Number",
                            "Sender_city",
                            "Sender_province",
                            "Sender_zip Code",
                            "Receiver_first Name",
                            "Receiver_last Name",
                            "Receiver_street Address",
                            "Receiver_home Number",
                            "Receiver_city",
                            "Receiver_province",
                            "Receiver_zip Code",
                            "Message"
                        ];

                        // Labels to exclude
                        const excludeLabels = new Set(
                            [
                                "Type",
                                "Document_id",
                                "Error Message",
                                "Tracking Number",
                                "Document"
                            ]
                        );

                        // Create a map of label to its order index
                        const orderMap = new Map(
                            order.map(
                                (label, index) => [label, index]
                            )
                        );

                        // Filter and sort the array
                        return array
                            .filter(item => !excludeLabels.has(item.label)) // Filter out excluded labels
                            .slice() // Create a shallow copy of the filtered array
                            .sort((a, b) => {
                                const indexA = orderMap.get(a.label) ?? Infinity;
                                const indexB = orderMap.get(b.label) ?? Infinity;
                                return indexA - indexB;
                            });
                    }
                    customerData = reorderArray(customerData);
                }

                if (serviceSlug === 'camerale') {
                    const reorderArray = array => {
                        // Define the desired order of labels
                        const order = [
                            "Type",
                            "Type Details",
                        ];

                        // Labels to exclude
                        const excludeLabels = new Set(
                            [
                                "Orders: 0",
                                "Document_id",
                                "Error Message",
                                "Tracking Number",
                                "Document"
                            ]
                        );

                        // Create a map of label to its order index
                        const orderMap = new Map(
                            order.map(
                                (label, index) => [label, index]
                            )
                        );

                        customerData.push(
                            {
                                label: "Type",
                                value: serviceData?.slug
                            },
                        );

                        // Filter and sort the array
                        return array
                            .filter(item => !excludeLabels.has(item.label)) // Filter out excluded labels
                            .slice() // Create a shallow copy of the filtered array
                            .sort((a, b) => {
                                const indexA = orderMap.get(a.label) ?? Infinity;
                                const indexB = orderMap.get(b.label) ?? Infinity;
                                return indexA - indexB;
                            });
                    }
                    customerData = reorderArray(customerData);
                }

                return customerData
                    .map(
                        entry => {
                            let value = entry.value;

                            if (
                                "document" === entry.label.toLowerCase() &&
                                null !== singlePurchase?.sendgrid_attachement_path &&
                                "" !== singlePurchase?.sendgrid_attachement_path
                            ) {
                                value = <a
                                    href={singlePurchase?.sendgrid_attachement_path}
                                    download={singlePurchase?.sendgrid_attachement_path}
                                >{entry.value}</a>;
                            }

                            return <React.Fragment key={entry.label}>
                                <dt>
                                    {entry.label.replace('_', ' ')}
                                </dt>
                                <dd>
                                <span>
                                    {value}
                                </span>
                                    {typeof entry.value !== "undefined" && (typeof entry.value === "string" && "" !== entry.value) &&
                                        <CopyDatabutton
                                            data={entry.value}
                                            label={entry.label}
                                        />}
                                </dd>
                            </React.Fragment>;
                        }
                    );
            }
        };

        const getPosteAPIData = () => {
            return <>
                Poste Data
            </>;
        };

        switch (this.props.singlePurchase?.status) {
            case "IN_PROGRESS":
                statusBadgeVariant = "primary";
                statusBadgeText    = "In Progress";
                break;
            case "PENDING":
                statusBadgeVariant = "dark";
                statusBadgeText    = "Pending";
                break;
            case "COMPLETED":
                statusBadgeVariant = "success";
                statusBadgeText    = "Completed";
                break;
            case "CANCELLED":
                statusBadgeVariant = "warning";
                statusBadgeText    = "Cancelled";
                break;
            case "CANCELLED_ORDER":
                statusBadgeVariant = "danger";
                statusBadgeText    = "Cancelled Subscription";
                break;
            case "ON_HOLD":
                statusBadgeVariant = "info";
                statusBadgeText    = "On Hold";
                break;
            case "UPLOAD":
                statusBadgeVariant = "secondary";
                statusBadgeText    = "Uploaded file";
                break;
        }

        return <>
            <div className="page-wrapper">
                {/* Navigation */}
                <Navigation onClick={this._onSideMenu} />
                {/* End Navigation */}

                <div className={`main-content d-flex flex-column ${this.state.sideMenu ? "" : "hide-sidemenu"}`}>

                    {/* Loader */}
                    {loader}
                    {/* End Loader */}

                    {/* Breadcrumb */}
                    <div className="main-content-header">
                        <Breadcrumb>
                            <h1>Clients</h1>
                            <Link to={routerConstants.dashboard} className="breadcrumb-item">
                                Dashboard
                            </Link>
                            <Link to={routerConstants.listPendingInProgressPurchases} className="breadcrumb-item">
                                Purchases
                            </Link>
                            <Breadcrumb.Item active>
                                Purchase
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                {this.clientFullName()}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    {/* End Breadcrumb */}

                    {this.pageAlerts()}
                    <ConfirmDeletComments
                        show={this.state.showCommentsModal}
                        onConfirm={this.deleteComment}
                        onClose={this.hideConfirmDeleteCommentsModal}
                        processing={this.state.processingCommentsDelete}
                        commentsId={this.state.commentsId}
                        userName={this.state.userName}
                    />

                    <ConfirmStatusChangeModal
                        show={this.state.showStatusChangeModal}
                        processing={this.state.processingStatusChange}
                        onClose={this.hideChangeStatusModalCallback}
                        onConfirm={this.setStatusToCancelledMethod}
                        purchaseId={this.state.purchaseId}
                        currentStatusText={this.state.currentStatusText}
                        nextStatusText={this.state.nextStatusText}
                        customerEmail={this.state.customerEmail}
                        customerLastName={this?.state?.customerLastName ?? ""}
                        customerFirstName={this?.state?.customerFirstName ?? ""}
                        requestMissingInfo={this.state.missingInformation}
                        emailTemplateValue={this.state.emailTemplateData}
                        emailTemplateOption={this.state.emailTemplate}
                        emailTemplateOnChange={this.onEmailTemplateChange}
                        nameOfService={this.state.nameOfService}
                        onUpdateDrivingLicensePoints={this.updateDrivingLicensePointsCallback}
                        showCommentsSection={this.showCommentsSection}
                        handleCommentsText={this.handleCommentsText}
                        commentsText={this.props.commentsText}
                    />

                    <UploadDocumentModal
                        show={this.props.showUploadDocumentModal}
                        customerLastName={this.state.customerLastName}
                        customerFirstName={this.state.customerFirstName}
                        purchaseId={this.state.purchaseId}
                        purchase={this.state.purchase}
                        onClose={this.hideUploadDocumentModal}
                        purchaseType={"PENDING_IN_PROGRESS_PURCHASE"}
                        getPurchase={this.getSinglePurchaseFunction}
                    />

                    <Row>
                        <Col lg={4} sm={12}>
                            <div className="card mb-4">
                                <div className="card-body">
                                    <div className="card-header d-flex justify-content-between align-items-baseline">
                                        <h5 className="card-title w-50 float-left">Purchase Data</h5>
                                        <Dropdown
                                            as={ButtonGroup}
                                            size="sm"
                                            className="d-inline-block mr-2 mt-2"
                                        >
                                            <Button
                                                variant={isUploadedOutline}
                                                as={"button"}
                                            >
                                                Details
                                            </Button>
                                            <Dropdown.Toggle
                                                split
                                                variant={isUploaded}
                                                id="dropdown-split-basic"
                                            />
                                            <Dropdown.Menu popperConfig={{strategy: "fixed"}}>
                                                <Dropdown.Item
                                                    href={customerFileLink}
                                                    onClick={e => this.goToRouteMethod(customerFileLink, e)}
                                                >
                                                    Customer File
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={e => this.gotToRouteNewPage(
                                                        `/list-purchases/purchase/${this.props.match.params.purchase_uuid}`,
                                                        e
                                                    )}
                                                >
                                                    Open in new page
                                                </Dropdown.Item>
                                                <Dropdown.Divider />
                                                <Dropdown.Header>
                                                    Purchase Actions
                                                </Dropdown.Header>
                                                <Dropdown.Item
                                                    onClick={
                                                        () => this
                                                            .showChangeStatusModalCallback(
                                                                this.props.match.params.purchase_uuid,
                                                                statusBadgeText,
                                                                "Not Delivered",
                                                                singlePurchase?.orders[0].customer.email,
                                                                singlePurchase?.orders[0].customer.lastName,
                                                                singlePurchase?.orders[0].customer.firstName,
                                                                "CANCELLED"
                                                            )
                                                    }
                                                >
                                                    Not Delivered
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={
                                                        () => this
                                                            .showChangeStatusModalCallback(
                                                                this.props.match.params.purchase_uuid,
                                                                statusBadgeText,
                                                                "Delivered",
                                                                singlePurchase?.orders[0]?.customer?.email,
                                                                singlePurchase?.orders[0]?.customer?.lastName,
                                                                singlePurchase?.orders[0]?.customer?.firstName,
                                                                "DELIVERED",
                                                                false,
                                                                singlePurchase?.service.slug
                                                            )
                                                    }
                                                >
                                                    Delivered
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={
                                                        () => this
                                                            .showChangeStatusModalCallback(
                                                                this.props.match.params.purchase_uuid,
                                                                statusBadgeText,
                                                                "On Hold",
                                                                singlePurchase?.orders[0].customer.email,
                                                                singlePurchase?.orders[0].customer.lastName,
                                                                singlePurchase?.orders[0].customer.firstName,
                                                                "ON_HOLD"
                                                            )
                                                    }
                                                >
                                                    On Hold
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={
                                                        () => this
                                                            .showChangeStatusModalCallback(
                                                                this.props.match.params.purchase_uuid,
                                                                statusBadgeText,
                                                                "On Hold",
                                                                singlePurchase?.orders[0].customer.email,
                                                                singlePurchase?.orders[0].customer.lastName,
                                                                singlePurchase?.orders[0].customer.firstName,
                                                                "REQUEST_MISSING_INFO",
                                                                true
                                                            )
                                                    }
                                                >
                                                    Ask Information
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={
                                                        () => this
                                                            .showUploadDocumentModal(
                                                                this.props.match.params.purchase_uuid,
                                                                singlePurchase?.customer_last_name,
                                                                singlePurchase?.customer_first_name,
                                                                singlePurchase,
                                                                true
                                                            )
                                                    }
                                                >
                                                    Upload
                                                    {
                                                        null !== singlePurchase?.sendgrid_attachement_path &&
                                                        "" !== singlePurchase?.sendgrid_attachement_path ?
                                                            <Badge
                                                                pill
                                                                variant="success"
                                                                className="mt-2 mr-2"
                                                            >
                                                                1
                                                            </Badge>
                                                            : null
                                                    }
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <dl className="customer-property-list single-column text-uppercase">
                                        {getPurchaseData()}
                                    </dl>
                                </div>
                            </div>
                            {
                                null !== documentInfo &&
                                <div className="card mb-4">
                                    <div className="card-body">
                                        <div className="card-header d-flex justify-content-between align-items-baseline">
                                            <h5 className="card-title w-50 float-left">Document Data</h5>
                                        </div>
                                        <dl className="customer-property-list single-column text-uppercase">
                                            <dt>
                                                Document ID
                                            </dt>
                                            <dd>
                                                <span>{documentInfo.id}</span>
                                                <CopyDatabutton data={documentInfo.id} />
                                            </dd>
                                            <dt>
                                                ID Pro
                                            </dt>
                                            <dd>
                                                <span>{documentInfo.id_pro}</span>
                                                <CopyDatabutton data={documentInfo.id_pro} />
                                            </dd>
                                            <dt>
                                                Status
                                            </dt>
                                            <dd>
                                                <span>{documentInfo.status}</span>
                                                <CopyDatabutton data={documentInfo.status} />
                                            </dd>
                                            <dt>
                                                Type
                                            </dt>
                                            <dd>
                                                <span>{documentInfo.type}</span>
                                                <CopyDatabutton data={documentInfo.type} />
                                            </dd>
                                            <dt>
                                                Message
                                            </dt>
                                            <dd>
                                                <span>{documentInfo.message}</span>
                                                <CopyDatabutton data={documentInfo.message} />
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            }
                            <div className="card mb-4">
                                <div className="card-body">
                                    <div className="card-header d-flex">
                                        <h5 className="card-title w-50 float-left">Order Details</h5>
                                    </div>
                                    <dl className="customer-property-list text-uppercase">
                                        {this.renderInfo()}
                                    </dl>
                                </div>
                            </div>
                        </Col>
                        <Col lg={8} sm={12}>
                            <div className="card mb-4">
                                <div className="card-body">

                                    <div className="card-header d-flex">
                                        <h5 className="card-title w-50 float-left">Purchase History</h5>
                                    </div>

                                    <Table className="m-0" responsive>
                                        <thead>
                                        <tr key="table_headers">
                                            <th>Email</th>
                                            <th width={"250px"}>Date</th>
                                            <th width={"250px"}>User</th>
                                            <th width={"170px"}>Product</th>
                                            <th width={"95px"}>Status</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.mapPurchaseHistory()}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>

                            <div className="card mb-4">
                                <div className="card-body">
                                    <div className="card-header d-flex justify-content-between align-items-baseline">
                                        <h5 className="card-title w-50 float-left">Comments</h5>
                                        <div>
                                            {this?.state?.newCommentLimiter === false
                                            && this?.state?.commentsElement?.length !== 0
                                                ?
                                                <Button
                                                    variant="danger"
                                                    size="sm"
                                                    className="rounded-pill"
                                                    onClick={() => this.addCommentField()}
                                                >
                                                    New Comment
                                                </Button>
                                                : null
                                            }
                                        </div>
                                    </div>
                                    <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                                        <Table className="m-0" responsive>
                                            <thead>
                                            <tr key="comments_section">
                                                <th><Badge variant="secondary">Comments</Badge></th>
                                                <th width={"220px"}>Date</th>
                                                <th width={"170px"}><Badge variant="secondary">Name</Badge></th>
                                                <th width={"95px"}><Badge variant="secondary">Actions</Badge></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this?.state?.commentsElement?.length === 0 ?
                                                <tr>
                                                    <td>
                                                            <textarea
                                                                id="commentText"
                                                                type="text"
                                                                rows="4"
                                                                cols="50"
                                                                className="form-control"
                                                                name={`comment`}
                                                                {...this.props.register(`newValue.newComment`)}

                                                            />
                                                    </td>
                                                    <td>
                                                        {DateUtils.formatISO8601ToFormat(new Date())}
                                                    </td>
                                                    <td>
                                                        {this.props.user.email}
                                                    </td>
                                                </tr> : null
                                            }
                                            {this?.state?.commentsElement?.map((comment, index) => {
                                                this.props.setValue(`comment[${index}].text`, comment?.commentText);

                                                return (
                                                    <tr key={comment.id}>
                                                        <td>
                                                                <textarea
                                                                    id="commentText"
                                                                    type="text"
                                                                    rows="4"
                                                                    cols="50"
                                                                    className="form-control"
                                                                    name={`comment[${index}]`}
                                                                    key={comment.id}
                                                                    readOnly
                                                                    {...this.props.register(`comment[${index}].text`)}
                                                                />
                                                        </td>
                                                        <td>
                                                            {DateUtils.formatISO8601ToFormat(comment["createdAt"])}
                                                        </td>
                                                        <td>
                                                            {comment.userEmail}
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <Dropdown
                                                                    as={ButtonGroup}
                                                                    size="sm"
                                                                    className="d-inline-block mr-2 mt-2"
                                                                >
                                                                    <Button
                                                                        variant="outline-primary"
                                                                        as={"button"}
                                                                    >
                                                                        Action
                                                                    </Button>
                                                                    <Dropdown.Toggle
                                                                        split
                                                                        variant="primary"
                                                                        id="dropdown-split-basic"
                                                                    />
                                                                    <Dropdown.Menu popperConfig={{strategy: "fixed"}}>
                                                                        <Dropdown.Item
                                                                            onClick={
                                                                                () => this
                                                                                    .showConfirmDeleteCommentsModal(
                                                                                        comment.id,
                                                                                        comment.userEmail,
                                                                                        index
                                                                                    )
                                                                            }
                                                                        >
                                                                            Delete
                                                                        </Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            {this.props.fields.map((item, index) => {
                                                return this.state.commentAdded ? (
                                                    <tr key={item.id}>
                                                        <td>
                                                            <textarea
                                                                rows="4"
                                                                cols="50"
                                                                className="form-control"
                                                                name={`newValue[${index}].newComment`}
                                                                {...this.props.register(`newValue[${index}].newComment`)}
                                                            />
                                                        </td>
                                                        <td>
                                                            {DateUtils.formatISO8601ToFormat(new Date())}
                                                        </td>
                                                        <td>
                                                            {this.props.user.email}
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <Trash
                                                                    onClick={() => this.removeCommentField()}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ) : null;
                                            })
                                            }
                                            </tbody>
                                        </Table>
                                        <Button
                                            variant="primary"
                                            onClick={this.props.handleSubmit(this.onSubmit)}
                                            className="mr-10"
                                            size="sm"
                                        >
                                            Submit Comment
                                        </Button>
                                    </form>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    {/* Footer */}
                    <div className="flex-grow-1" />
                    <Footer />
                    {/* End Footer */}
                </div>
            </div>
        </>;
    }
}

const ListSinglePurchasePage = (props) => {
    const {
              register,
              handleSubmit,
              setValue,
              formState: {errors},
              control,
              reset
          } = useForm();

    const {
              fields,
              append,
              remove
          } = useFieldArray({
                                control,
                                name: "newValue"
                            });

    return (
        <ListSinglePurchase
            {...props}
            register={register}
            handleSubmit={handleSubmit}
            setValue={setValue}
            errors={errors}
            control={control}
            reset={reset}
            fields={fields}
            append={append}
            remove={remove}
        />

    );
};

const mapStateToProps = state => {
    return {
        singlePurchase         : state.purchases.singlePurchase,
        singleUser             : state.user.me,
        loadingSinglePurchase  : state.purchases.loadingSinglePurchase,
        singlePurchaseError    : state.purchases.singlePurchaseError,
        isCopied               : state.purchases.isTextCopied,
        user                   : state.user.me,
        commentsSuccess        : state.comments.createCommentsSuccess,
        commentsError          : state.comments.createCommentsError,
        deleteSuccess          : state.comments.deleteCommentsSuccess,
        deleteError            : state.comments.deleteCommentsError,
        commentsLoading        : state.comments.createCommentsLoading,
        deleteLoading          : state.comments.deleteCommentsLoading,
        purchaseComments       : state.purchases.purchaseCommentsData,
        purchaseCommentsLoading: state.purchases.purchaseCommentsLoading,
        commentsPurchaseError  : state.purchases.purchaseCommentsError,
        commentsText           : state.purchases.modalConfirmationComments,
        emailTemplate          : state.emailTemplates.emailTemplateList,
        errorEmailTempltate    : state.emailTemplates.errorEmailTemplate,
        emailTemplateLoading   : state.emailTemplates.loadingEmailTemplate,
        showUploadDocumentModal: state.purchases.showUploadDocumentModal,
        deleteErrorDocument    : state.purchases.deleteDocumentError,
        deleteSuccessDocument  : state.purchases.deleteDocumentSuccess,
        uploadSuccess          : state.purchases.purchasesUploadedDocumentSuccess,
        uploadError            : state.purchases.purchasesUploadedDocumentError
    };
};

const ListSinglePurchaseConnected = connect(
    mapStateToProps,
    {
        getSinglePurchase,
        setSinglePurchase,
        getSingleClient,
        postComment,
        deleteComment,
        updateComment,
        getSinglePurchaseComments,
        setStatusToCompleted,
        setStatusToCancelled,
        setStatusToOnHold,
        setPurchaseDrivingLicensePoints,
        requestMissingInformation,
        getEmailTemplatesList

    }
)(ListSinglePurchasePage);

export default withRouter(ListSinglePurchaseConnected);
