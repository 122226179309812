import React                     from "react";
import Navigation                from "../../components/Navigation/Navigation";
import {
    Alert,
    Badge,
    Breadcrumb,
    Button,
    ButtonGroup,
    Col,
    Dropdown,
    DropdownButton,
    Form,
    FormControl,
    InputGroup,
    Row,
    Tab,
    Table,
    Tabs
}                                from "react-bootstrap";
import Footer                    from "../Footer/Footer";
import Loader                    from "../../components/Common/Loader";
import {connect}                 from "react-redux";
import {
    Link,
    withRouter
}                                from "react-router-dom";
import {
    getTotalOnHoldTransactions,
    getTotalPendingInProgressTransactions,
    getTotalAskInformationTransactions,
    meiliSearch,
    requestMissingInformation,
    setPurchaseDrivingLicensePoints,
    setStatusToCancelled,
    setStatusToCompleted,
    setStatusToOnHold
}                                from "../../redux/Purchases/purchases.async-actions";
import {
    getServicesList
}                                from "../../redux/Services/services.async-actions";
import DateUtils                 from "../../services/DateUtils";
import routerConstants           from "../../constants/router-constants";
import UUIDs                     from "../../services/UUIDs";
import * as Icon                 from "react-feather";
import Paginator                 from "../../components/Common/Paginator";
import ConfirmStatusChangeModal  from "./Modals/ConfirmStatusChangeModal";
import UploadDocumentModal       from "./Modals/UploadDocumentModal";
import {
    resetMeiliPagination,
    setConfirmationComments,
    setIsFilePicked,
    setShowUploadDocumentModal
}                                from "../../redux/Purchases/purchases.actions";
import store                     from "../../redux/store";
import {
    getEmailTemplatesList
}                                from "../../redux/EmailTemplate/emailTemplate.async-actions";
import JsCookie                  from "js-cookie";
import ReactTooltip              from "react-tooltip";
import ConfirmBulkActionModal    from "./Modals/ConfirmBulkActionModal";
import CopyDatabutton            from "../../components/Common/CopyDataButton";
import MultiSelect               from "../../components/Common/CustomMultiSelect";
import {setResetFilterPurchases} from "../../redux/Global/global.actions";

class ListPurchases extends React.Component {
    state = {
        sideMenu                    : true,
        showStatusChangeModal       : false,
        processingStatusChange      : false,
        purchaseId                  : null,
        currentStatusText           : "",
        nextStatusText              : "",
        customerEmail               : "",
        customerLastName            : "",
        customerFirstName           : "",
        operation                   : "",
        nameOfService               : "",
        drivingLicensePoints        : 0,
        proDrivingLicensePoints     : null,
        missingInformation          : false,
        uploading                   : false,
        hideDownloadModal           : false,
        purchase                    : null,
        selectedFieldsIds           : [],
        emailTemplateData           : {},
        emailTemplate               : [],
        drivingLicensePointsNotValid: false,
        showBulkActionModalState    : false,
        purchaseStatusModal         : "",
        bulkAction                  : "",

        searchField     : "any_field",
        searchFieldLabel: "",
        searchTerm      : "",
        currentPage     : 1,

        activeTab     : "pending",
        searchStatuses: "status IN [IN_PROGRESS, PENDING, CANCELLED_ORDER]",
        orderBy       : "subscription_started:asc",

        searchCriteria: {
            pending       : {
                searchField       : "any_field",
                searchFieldLabel  : "",
                searchTerm        : "",
                searchStatuses    : "status IN [IN_PROGRESS, PENDING, CANCELLED_ORDER]",
                orderBy           : "subscription_started:asc",
                from              : "",
                to                : "",
                service           : [],
                subscriptionStatus: "",
                purchaseType      : "",
                currentPage       : 1,
                selectedFieldsIds : []
            },
            hold          : {
                searchField       : "any_field",
                searchFieldLabel  : "",
                searchTerm        : "",
                searchStatuses    : "status IN [ON_HOLD]",
                orderBy           : "subscription_started:asc",
                from              : "",
                to                : "",
                service           : [],
                subscriptionStatus: "",
                purchaseType      : "",
                currentPage       : 1,
                selectedFieldsIds : []
            },
            askInformation: {
                searchField       : "any_field",
                searchFieldLabel  : "",
                searchTerm        : "",
                searchStatuses    : "status IN [ASK_INFORMATION]",
                orderBy           : "subscription_started:asc",
                from              : "",
                to                : "",
                service           : [],
                subscriptionStatus: "",
                purchaseType      : "",
                currentPage       : 1,
                selectedFieldsIds : []
            },
            delivered     : {
                searchField       : "any_field",
                searchFieldLabel  : "",
                searchTerm        : "",
                searchStatuses    : "status IN [COMPLETED, CANCELLED]",
                orderBy           : "purchase_updated:desc",
                from              : "",
                to                : "",
                service           : [],
                subscriptionStatus: "",
                purchaseType      : "",
                currentPage       : 1,
                selectedFieldsIds : []
            }
        }
    };

    constructor(props) {
        super(props);

        this.searchFieldList         = React.createRef();
        this.searchTermField         = React.createRef();
        this.fromDate                = React.createRef();
        this.toDate                  = React.createRef();
        this.serviceName             = React.createRef();
        this.subscriptionStatusField = React.createRef();
        this.purchaseType            = React.createRef();

        this.resetFormFiltersMethod             = this.resetFormFilters.bind(this);
        this.getPageToGoMethod                  = this.getPageToGo.bind(this);
        this.goToRouteMethod                    = this.goToRoute.bind(this);
        this.setStatusToCancelledMethod         = this.updatePurchaseStatus.bind(this);
        this.showChangeStatusModalCallback      = this.showChangeStatusModal.bind(this);
        this.updateDrivingLicensePointsCallback = this.updateDrivingLicensePointsMethod.bind(this);
        this.hideChangeStatusModalCallback      = this.hideChangeStatusModal.bind(this);
        this.searchPurchases                    = this.searchPurchases.bind(this);
        this.changePurchaseStatus               = this.changePurchaseStatus.bind(this);
        this.meiliSearchForm                    = this.meiliSearchForm.bind(this);
        this.purchasesUI                        = this.purchasesUI.bind(this);
        this.emailTemplatesOptions              = [];

        this.searchFields = [
            {
                key  : "any_field",
                value: "Any Field"
            },
            {
                key  : "customer_email",
                value: "Email"
            },
            {
                key  : "customer_first_name",
                value: "First Name"
            },
            {
                key  : "customer_last_name",
                value: "Last Name"
            },
            {
                key  : "license_plate_number",
                value: "License Plate Number"
            },
            {
                key  : "driving_licence_number",
                value: "Driving License Number"
            },
            {
                key  : "company_name",
                value: "Company Name"
            },
            {
                key  : "var_number",
                value: "VAT Number"
            },
            {
                key  : "fiscal_code",
                value: "Fiscal Code"
            },
            {
                key  : "order_number",
                value: "Order Number"
            },
            {
                key  : "attachment_path",
                value: "File Name"
            },
            {
                key  : "comments",
                value: "Comment Text"
            }
        ];
    }

    /**
     * Run once the component is mounted in the app to initialize the component.
     *
     * @returns {Promise<void>}
     */
    async componentDidMount() {
        this.fakeTabStyling();
        await this.getEmailTemplateNotDeliveredService();
        await this.props.getServicesList();
        await this.props.getTotalAskInformationTransactions();

        this.loadSearchFormValuesFromCookies();
        this.loadEmailTemplateSelect();

        this
            .setState(
                {
                    searchCriteria: {
                        ...this.state.searchCriteria,
                        pending: {
                            ...this.state.searchCriteria.pending,
                            searchFieldLabel: this
                                .searchFields
                                .filter(
                                    field => field.key === this.state.searchCriteria.pending.searchField
                                )[0].value
                        }
                    }
                }
            );
        this
            .setState(
                {
                    searchCriteria: {
                        ...this.state.searchCriteria,
                        hold: {
                            ...this.state.searchCriteria.hold,
                            searchFieldLabel: this
                                .searchFields
                                .filter(
                                    field => field.key === this.state.searchCriteria.hold.searchField
                                )[0].value
                        }
                    }
                }
            );
        this.setState(
            {
                searchCriteria: {
                    ...this.state.searchCriteria,
                    askInformation: {
                        ...this.state.searchCriteria.askInformation,
                        searchFieldLabel: this
                            .searchFields
                            .filter(
                                field => field.key === this.state.searchCriteria.askInformation.searchField
                            )[0].value
                    }
                }
            }
        );
        this
            .setState(
                {
                    searchCriteria: {
                        ...this.state.searchCriteria,
                        delivered: {
                            ...this.state.searchCriteria.delivered,
                            searchFieldLabel: this
                                .searchFields
                                .filter(
                                    field => field.key === this.state.searchCriteria.delivered.searchField
                                )[0].value
                        }
                    }
                }
            );

        window.requestAnimationFrame(async () => await this.searchPurchases());
    }

    fakeTabStyling = () => {
        let style = document.querySelector("style#fakeTab");

        if (null === style) {
            const rules     = [
                "border-bottom: none",
                "border-left: none",
                "border-right: none",
                "padding: 0"
            ];
            style           = document.createElement("style");
            style.id        = "fakeTab";
            style.innerText = `.fakeTab + .tab-content {${rules.join(";")}`;
            document.head.appendChild(style);
        }
    };

    /**
     * Read the Search Form values from Cookie.
     */
    loadSearchFormValuesFromCookies() {
        const cookie                = JsCookie.get("psfv");
        const defaultSearchCriteria = {
            pending       : {
                searchField       : "any_field",
                searchFieldLabel  : "",
                searchTerm        : "",
                searchStatuses    : "status IN [IN_PROGRESS, PENDING, CANCELLED_ORDER]",
                orderBy           : "purchase_created:desc",
                from              : "",
                to                : "",
                service           : [],
                subscriptionStatus: "",
                purchaseType      : "",
                currentPage       : 1,
                selectedFieldsIds : []
            },
            hold          : {
                searchField       : "any_field",
                searchFieldLabel  : "",
                searchTerm        : "",
                searchStatuses    : "status IN [ON_HOLD]",
                orderBy           : "purchase_created:desc",
                from              : "",
                to                : "",
                service           : [],
                subscriptionStatus: "",
                purchaseType      : "",
                currentPage       : 1,
                selectedFieldsIds : []
            },
            askInformation: {
                searchField       : "any_field",
                searchFieldLabel  : "",
                searchTerm        : "",
                searchStatuses    : "status IN [ASK_INFORMATION]",
                orderBy           : "purchase_created:desc",
                from              : "",
                to                : "",
                service           : [],
                subscriptionStatus: "",
                purchaseType      : "",
                currentPage       : 1,
                selectedFieldsIds : []
            },
            delivered     : {
                searchField       : "any_field",
                searchFieldLabel  : "",
                searchTerm        : "",
                searchStatuses    : "status IN [COMPLETED, CANCELLED]",
                orderBy           : "purchase_created:desc",
                from              : "",
                to                : "",
                service           : [],
                subscriptionStatus: "",
                purchaseType      : "",
                currentPage       : 1,
                selectedFieldsIds : []
            }
        };

        if (typeof undefined !== typeof cookie) {
            const [searchCriteria, tab] = cookie.split("=+=+=+=").map(part => atob(part));

            if (searchCriteria?.pending?.selectedFieldsIds) {
                searchCriteria.pending.selectedFieldsIds = [];
            }
            if (searchCriteria?.hold?.selectedFieldsIds) {
                searchCriteria.hold.selectedFieldsIds = [];
            }
            if (searchCriteria?.delivered?.selectedFieldsIds) {
                searchCriteria.delivered.selectedFieldsIds = [];
            }

            this
                .setState(
                    {
                        searchCriteria: {
                            ...defaultSearchCriteria,
                            ...JSON.parse(searchCriteria)
                        },
                        activeTab     : tab
                    }
                );

            document.querySelector(`#${tab}Tab`).click();
        }
    }

    /**
     * Whatching the showStatusChangeModal state variable to clear confirmation comments and update the component
     * state variable setShowComments to false.
     *
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.showStatusChangeModal !== prevState.showStatusChangeModal) {
            store.dispatch(setConfirmationComments(""));
            this.setState(
                {
                    setShowComments: false
                }
            );
        }
    }

    /**
     * Toggle side bar menu
     *
     * @param active
     * @private
     */
    _onSideMenu = (active) => {
        this.setState({sideMenu: active});
    };

    /**
     * Set the drivingLicensePoints state value to the provided value number.
     *
     * @param drivingLicensePoints
     */
    updateDrivingLicensePointsMethod(drivingLicensePoints = 0, proDrivingLicensePoints = null) {
        this
            .setState(
                {
                    drivingLicensePoints   : parseInt(drivingLicensePoints, 10),
                    proDrivingLicensePoints: null === proDrivingLicensePoints ? null : parseInt(
                        proDrivingLicensePoints,
                        10
                    )
                }
            );
    }

    /**
     * Loading the values related to the available email templates.
     */
    loadEmailTemplateSelect = () => {
        if (null === this?.props?.errorEmailTempltate) {
            this.emailTemplatesOptions = this
                ?.props
                ?.emailTemplate
                ?.map(
                    emailTemplate => {
                        return {
                            value: UUIDs.getEntityUUID(emailTemplate["@id"]),
                            label: emailTemplate.description
                        };
                    }
                ) ?? {};

            const noSendEmail = {
                value: "",
                label: "NO EMAIL"
            };

            const updateOptions = [
                noSendEmail,
                ...this.emailTemplatesOptions
            ];

            this.setState(
                {
                    emailTemplate: updateOptions
                }
            );
        }
    };

    /**
     * Update the emailTemplateData state to the new value
     *
     * @param emailTemplate
     */
    onEmailTemplateChange = emailTemplate => this.setState({emailTemplateData: emailTemplate});

    /**
     * Responsible to get the email templates from the server
     * @param queryParams
     * @returns {Promise<void>}
     */
    async getEmailTemplateNotDeliveredService(queryParams = {}) {
        await this
            .props
            .getEmailTemplatesList(
                {
                    ...{
                        name: "SENDGRID_NOT_DELIVERED_SERVICE_EMAIL"
                    },
                    ...queryParams
                }
            );
    }

    /**
     * Set the currentPage when pagination change and loading the results for the given page.
     *
     * @param currentPage
     */
    getPageToGo(currentPage) {
        const tab = this.state.activeTab;

        if (currentPage) {
            this.setState(
                {
                    searchCriteria: {
                        ...this.state.searchCriteria,
                        [tab]: {
                            ...this.state.searchCriteria[tab],
                            ...{
                                currentPage: currentPage
                            }
                        }
                    }
                }
            );

            window.requestAnimationFrame(async () => await this.searchPurchases());
        }
    }

    /**
     * Reseting the search form field values.
     */
    resetFormFilters() {
        const tab                   = this.state.activeTab;
        store.dispatch(setResetFilterPurchases(true));

        const defaultSearchCriteria = {
            pending       : {
                searchField       : "any_field",
                searchFieldLabel  : "",
                searchTerm        : "",
                searchStatuses    : "status IN [IN_PROGRESS, PENDING, CANCELLED_ORDER]",
                orderBy           : "subscription_started:desc",
                from              : "",
                to                : "",
                service           : [],
                subscriptionStatus: "",
                purchaseType      : "",
                currentPage       : 1,
                selectedFieldsIds : []
            },
            hold          : {
                searchField       : "any_field",
                searchFieldLabel  : "",
                searchTerm        : "",
                searchStatuses    : "status IN [ON_HOLD]",
                orderBy           : "subscription_started:asc",
                from              : "",
                to                : "",
                service           : [],
                subscriptionStatus: "",
                purchaseType      : "",
                currentPage       : 1,
                selectedFieldsIds : []
            },
            askInformation: {
                searchField       : "any_field",
                searchFieldLabel  : "",
                searchTerm        : "",
                searchStatuses    : "status IN [ASK_INFORMATION]",
                orderBy           : "subscription_started:asc",
                from              : "",
                to                : "",
                service           : [],
                subscriptionStatus: "",
                purchaseType      : "",
                currentPage       : 1,
                selectedFieldsIds : []
            },
            delivered     : {
                searchField       : "any_field",
                searchFieldLabel  : "",
                searchTerm        : "",
                searchStatuses    : "status IN [COMPLETED, CANCELLED]",
                orderBy           : "purchase_updated:desc",
                from              : "",
                to                : "",
                service           : [],
                subscriptionStatus: "",
                purchaseType      : "",
                currentPage       : 1,
                selectedFieldsIds : []
            }
        };

        this
            .setState(
                {
                    searchCriteria: {
                        ...this.state.searchCriteria,
                        [tab]: {
                            ...this.state.searchCriteria[tab],
                            ...defaultSearchCriteria[tab]
                        }
                    }
                }
            );

        if (this.serviceName && this.serviceName.current) {
            this.serviceName.current.value = this.state.searchCriteria[this.state.activeTab].service;
        }

        this.searchFieldList.current.value         = "any_field";
        this.searchTermField.current.value         = "";
        this.fromDate.current.value                = "";
        this.toDate.current.value                  = "";
        this.subscriptionStatusField.current.value = "";
        this.purchaseType.current.value            = "";

        this.resetPagination();

        window.requestAnimationFrame(async () => await this.searchPurchases());
        window.requestAnimationFrame(
            () => {
                this
                    .setState(
                        {
                            searchCriteria: {
                                ...this.state.searchCriteria,
                                ...{
                                    [tab]: {
                                        ...this.state.searchCriteria[tab],
                                        searchFieldLabel: this
                                            .searchFields
                                            .filter(
                                                field => field.key === this.state.searchCriteria[tab].searchField
                                            )[0].value
                                    }
                                }
                            }
                        }
                    );
            }
        );
        window.requestAnimationFrame(
            () => {
                this.setState(
                    {
                        searchFieldLabel: this
                            .searchFields
                            .filter(
                                field => field.key === this.state.searchField
                            )[0].value
                    }
                );
            }
        );
    }

    /**
     * For few interactions it is required to reset the pagination current page to number 1.
     */
    resetPagination = () => {
        this.setState(
            {
                currentPage: 1
            }
        );
    }

    /**
     * Redirect user to provided route.
     *
     * @param route
     * @param e
     */
    goToRoute(route, e) {
        e.stopPropagation();
        e.preventDefault();

        this
            .props
            .history
            .push(route);
    }

    /**
     * Open given route to a new browser tab
     *
     * @param route
     * @param e
     */
    gotToRouteNewPage(route, e) {
        e.stopPropagation();
        e.preventDefault();

        this
            .props
            .history
            .push(
                window.open(route)
            );
    }

    /**
     * Change the purchase status depending on the user action.
     *
     * @returns {Promise<void>}
     */
    async updatePurchaseStatus() {
        const tab = this.state.activeTab;

        this.setState(
            {
                processingStatusChange: true
            }
        );

        switch (this.state.operation) {
            case "CANCELLED":
                await this
                    .props
                    .setStatusToCancelled(
                        this.state.purchaseId,
                        this.state.emailTemplateData,
                        this.props.commentsText
                    );
                break;
            case "DELIVERED":
                if (this.state.drivingLicensePoints > 30 || this.state.proDrivingLicensePoints > 30) {
                    this
                        .setState(
                            {
                                drivingLicensePointsNotValid: true,
                                processingStatusChange      : false
                            }
                        );
                    this.hideChangeStatusModalCallback();
                    return;
                }

                if ("driving_licence" === this.state.nameOfService) {
                    await this
                        .props
                        .setPurchaseDrivingLicensePoints(
                            this.state.purchaseId,
                            parseInt(this.state.drivingLicensePoints, 10),
                            null === this.state.proDrivingLicensePoints ? null : parseInt(
                                this.state.proDrivingLicensePoints,
                                10
                            )
                        );
                }

                this.setState(
                    {
                        drivingLicensePointsNotValid: false
                    }
                );
                await this
                    .props
                    .setStatusToCompleted(
                        this.state.purchaseId,
                        null,
                        this.props.commentsText
                    );
                break;
            case "ON_HOLD":
                await this
                    .props
                    .setStatusToOnHold(
                        this.state.purchaseId,
                        this.state.selectedFieldsIds,
                        this.props.commentsText
                    );
                break;
            case "REQUEST_MISSING_INFO":
                await this
                    .props
                    .requestMissingInformation(
                        this.state.purchaseId,
                        this.state.selectedFieldsIds,
                        this.props.commentsText
                    );
                break;
        }

        await this.props.getTotalPendingInProgressTransactions();
        await this.props.getTotalOnHoldTransactions();
        await this.props.getTotalAskInformationTransactions();

        this
            .setState(
                {
                    processingStatusChange: false,
                    showStatusChangeModal : false
                }
            );

        this.setState(
            {
                searchCriteria: {
                    ...this.state.searchCriteria,
                    [tab]: {
                        ...this.state.searchCriteria[tab],
                        ...{
                            currentPage: 1
                        }
                    }
                }
            }
        );

        setTimeout(
            async () => {
                await this.props.getTotalPendingInProgressTransactions();
                await this.props.getTotalOnHoldTransactions();
                await this.props.getTotalAskInformationTransactions();
                await this.searchPurchases();
            },
            1000
        );
    }

    /**
     * Display the upload modal dialog.
     * @param purchaseId
     * @param lastName
     * @param firstName
     * @param purchase
     */
    showUploadDocumentModal(
        purchaseId,
        lastName,
        firstName,
        purchase
    ) {
        store
            .dispatch(
                setShowUploadDocumentModal(true)
            );

        this
            .setState(
                {
                    purchaseId,
                    customerLastName : lastName,
                    customerFirstName: firstName,
                    purchase         : purchase
                }
            );
    }

    /**
     * Hide the upload modal dialog and reset the state.
     */
    hideUploadDocumentModal = () => {
        store
            .dispatch(
                setShowUploadDocumentModal(false)
            );
        store
            .dispatch(
                setIsFilePicked(false)
            );
        this
            .setState(
                {
                    customerLastName : "",
                    customerFirstName: ""
                }
            );
    };

    /**
     * Display the change status modal dialog.
     * @param purchaseId
     * @param currentStatusText
     * @param nextStatusText
     * @param email
     * @param lastName
     * @param firstName
     * @param operation
     * @param missingInfo
     * @param serviceName
     */
    showChangeStatusModal(
        purchaseId,
        currentStatusText,
        nextStatusText,
        email,
        lastName,
        firstName,
        operation,
        missingInfo = false,
        serviceName = ""
    ) {
        this
            .setState(
                {
                    showStatusChangeModal: true,
                    purchaseId,
                    currentStatusText,
                    nextStatusText,
                    customerEmail        : email,
                    customerLastName     : lastName,
                    customerFirstName    : firstName,
                    operation,
                    missingInformation   : missingInfo,
                    nameOfService        : serviceName
                }
            );
    }

    /**
     * Hide the change status modal dialog and reset the state.
     */
    hideChangeStatusModal() {
        this
            .setState(
                {
                    showStatusChangeModal: false,
                    purchaseId           : null,
                    currentStatusText    : "",
                    nextStatusText       : "",
                    customerEmail        : "",
                    customerLastName     : "",
                    customerFirstName    : "",
                    operation            : ""
                }
            );
    }

    showBulkActionModal = (
        purchaseStatus,
        action = ""
    ) => {
        this
            .setState(
                {
                    showBulkActionModalState: true,
                    purchaseStatusModal     : purchaseStatus,
                    bulkAction              : action
                }
            );
    };

    hideBulkActionModal = () => {
        this
            .setState(
                {
                    showBulkActionModalState: false,
                    purchaseStatusModal     : "",
                    bulkAction              : ""
                }
            );
    };

    /**
     * Get a purchase ID and based on it, update the selectedFieldsIds
     *
     * @param purchaseId
     */
    handleBulkAction = purchaseId => {
        const tab = this.state.activeTab;

        const updatedArray = [
            ...this
                .state
                .searchCriteria[tab]
                .selectedFieldsIds
        ];
        const index        = updatedArray.indexOf(purchaseId);

        if (index !== -1) {
            updatedArray.splice(index, 1);
        } else {
            updatedArray.push(purchaseId);
        }

        this
            .setState(
                {
                    searchCriteria: {
                        ...this.state.searchCriteria,
                        [tab]: {
                            ...this.state.searchCriteria[tab],
                            selectedFieldsIds: [
                                ...updatedArray
                            ]
                        }
                    }
                }
            );
    };

    /**
     * Get the selectedFieldsIds and update the searchCriteria with them.
     */
    selectAllPurchases = () => {
        const tab = this.state.activeTab;

        const allPurchaseIds = this.props.purchases.meiliPurchases
                                   .filter(purchase => purchase.service_slug !== "driving_licence")
                                   .map(purchase => purchase.id);
        this.setState({
                          searchCriteria: {
                              ...this.state.searchCriteria,
                              [tab]: {
                                  ...this.state.searchCriteria[tab],
                                  selectedFieldsIds: allPurchaseIds
                              }
                          }
                      });
    };

    /**
     * Unselect all the selectedFieldsIds.
     */
    unselectAllPurchases = () => {
        const tab = this.state.activeTab;

        this.setState({
                          searchCriteria: {
                              ...this.state.searchCriteria,
                              [tab]: {
                                  ...this.state.searchCriteria[tab],
                                  selectedFieldsIds: []
                              }
                          }
                      });
    };

    /**
     * Map the fetched purchases from Meilisearch into HTML for the purchases table.
     *
     * @returns {unknown[]}
     */
    mapMeiliPurchases = () => {
        const tab = this.state.activeTab;

        return this
            .props
            .purchases
            .meiliPurchases
            .map(
                purchase => {
                    const purchaseId              = purchase.id;
                    const orderId                 = purchase.order_id;
                    const customerFileLink        = `/list-clients/${orderId}`;
                    let statusBadgeVariant        = "primary";
                    let statusBadgeText           = "In Progress";
                    let typeBadgeVariant          = "primary";
                    let typeBadgeText             = "Sale";
                    let subscriptionStatusVariant = "";
                    let subscriptionStatusText    = "";
                    let isUploaded                = null !== purchase?.attachment_path && "" !== purchase?.attachment_path ? "success" : "primary";
                    let isUploadedOutline         = null !== purchase?.attachment_path && "" !== purchase?.attachment_path ? "outline-success" : "outline-primary";
                    const purchaseType            = this.state.searchCriteria[tab].purchaseType;
                    const isPendingOnHoldOrInfo   = (tab === "pending" || tab === "hold" || tab === "askInformation");
                    const isDelivered           = (tab === "delivered");
                    let date;

                    switch (purchase.status) {
                        case "IN_PROGRESS":
                            statusBadgeVariant = "primary";
                            statusBadgeText    = "In Progress";
                            break;
                        case "PENDING":
                            statusBadgeVariant = "dark";
                            statusBadgeText    = "Pending";
                            break;
                        case "COMPLETED":
                            statusBadgeVariant = "success";
                            statusBadgeText    = "Completed";
                            break;
                        case "CANCELLED":
                            statusBadgeVariant = "warning";
                            statusBadgeText    = "Cancelled";
                            break;
                        case "CANCELLED_ORDER":
                            statusBadgeVariant = "danger";
                            statusBadgeText    = "Cancelled Subscription";
                            break;
                        case "ON_HOLD":
                            statusBadgeVariant = "info";
                            statusBadgeText    = "On Hold";
                            break;
                        case "ASK_INFORMATION":
                            statusBadgeVariant = "warning";
                            statusBadgeText    = "Ask Information";
                            break;
                    }

                    switch (purchase.order_type) {
                        case "SALES":
                            typeBadgeVariant = "success";
                            typeBadgeText    = "Sale";
                            break;
                        case "CONNECTED_AREA":
                            typeBadgeVariant = "primary";
                            typeBadgeText    = "Subscription";
                            break;
                    }

                    switch (purchase.is_subscribed) {
                        case "false":
                            subscriptionStatusVariant = "danger";
                            subscriptionStatusText    = "Inactive";
                            break;
                        default:
                            subscriptionStatusVariant = "success";
                            subscriptionStatusText    = "Active";
                    }
                    
                    if (purchase.order_type === "SALES") {
                        if (isPendingOnHoldOrInfo) {
                            date = purchase.subscription_started;
                        }

                        if (isDelivered) {
                            date = purchase.purchase_updated;
                        }
                    }

                   if (purchase.order_type === "CONNECTED_AREA") {
                        if (isPendingOnHoldOrInfo) {
                            date = purchase.purchase_created;
                        }

                        if (isDelivered) {
                            date = purchase.purchase_updated;
                        }
                   }
   
                   if (purchase.order_type === "") {
                       date = purchase.purchase_updated;
                   }

                return <tr key={purchaseId}>
                        <td>
                            <div
                                className="d-flex flex-row justify-content-start align-items-start p-2"
                                style={{"gap": "15px"}}
                            >
                                <input
                                    id={`checkbox-${purchaseId}`}
                                    type="checkbox"
                                    value=""
                                    checked={this.state.searchCriteria[tab].selectedFieldsIds.includes(purchaseId)}
                                    onChange={() => this.handleBulkAction(purchaseId)}
                                    disabled={purchase?.service_slug === "driving_licence"}
                                />
                                <div
                                    className="copy-container d-flex flex-row flex-grow-1 justify-content-start align-items-start"
                                    style={{"gap": "15px"}}
                                >
                                    <Link
                                        to={customerFileLink}
                                        className="d-flex flex-column flex-grow-1 customerData"
                                        style={{"gap": "7px"}}
                                    >
                                        <span className="customerEmail">{purchase.customer_email}</span>
                                        {
                                            purchase?.customer_first_name.trim() !== "" &&
                                            purchase?.customer_last_name.trim() !== "" &&
                                            <small className="customerName">
                                                {`${purchase?.customer_last_name ?? ""} ${purchase?.customer_first_name ?? ""}`.trim()}
                                            </small>
                                        }
                                    </Link>
                                    <CopyDatabutton
                                        data={purchase.customer_email}
                                    />
                                </div>
                            </div>
                        </td>
                        <td>
                            {purchase?.descriptor ?? "-- NO VALUE --"}
                        </td>
                        <td>
                            {DateUtils.formatUnixTimestampToFormat(date)}
                            &nbsp;
                            <small>({DateUtils.formatTimestampToHumanReadable(date)}</small>
                        </td>
                        <td>
                            {purchase.service}
                        </td>
                        <td>
                            <Badge pill variant={statusBadgeVariant} className="mt-2 mr-2">
                                {statusBadgeText}
                            </Badge>
                        </td>
                        <td>
                            <Badge pill variant={typeBadgeVariant} className="mt-2 mr-2">
                                {typeBadgeText}
                            </Badge>
                        </td>
                        <td>
                            <Badge pill variant={subscriptionStatusVariant} className="mt-2 mr-2">
                                {subscriptionStatusText}
                            </Badge>
                        </td>
                        <td>
                            <Dropdown
                                as={ButtonGroup}
                                size="sm"
                                className="d-inline-block mr-2 mt-2"
                            >
                                <Button
                                    variant={isUploadedOutline}
                                    as={"button"}
                                    onClick={e => this.goToRouteMethod(`/list-purchases/purchase/${purchaseId}`, e)}
                                >
                                    Details
                                </Button>
                                <Dropdown.Toggle
                                    split
                                    variant={isUploaded}
                                    id="dropdown-split-basic"
                                />
                                <Dropdown.Menu popperConfig={{strategy: "fixed"}}>
                                    <Dropdown.Item
                                        href={customerFileLink}
                                        onClick={e => this.goToRouteMethod(customerFileLink, e)}
                                    >
                                        Customer File
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={e => this.gotToRouteNewPage(
                                            `/list-purchases/purchase/${purchaseId}`,
                                            e
                                        )}
                                    >
                                        Open in new page
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    {
                                        this.state.selectedFieldsIds.length === 0 ?
                                            <>
                                                <Dropdown.Header>
                                                    Purchase Actions
                                                </Dropdown.Header>
                                                <Dropdown.Item
                                                    onClick={
                                                        () => this
                                                            .showChangeStatusModalCallback(
                                                                purchaseId,
                                                                statusBadgeText,
                                                                "Not Delivered",
                                                                purchase.customer_email,
                                                                purchase.customer_last_name,
                                                                purchase.customer_first_name,
                                                                "CANCELLED"
                                                            )
                                                    }
                                                >
                                                    Not Delivered
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={
                                                        () => this
                                                            .showChangeStatusModalCallback(
                                                                purchaseId,
                                                                statusBadgeText,
                                                                "Delivered",
                                                                purchase.customer_email,
                                                                purchase.customer_last_name,
                                                                purchase.customer_first_ame,
                                                                "DELIVERED",
                                                                false,
                                                                purchase.service_slug
                                                            )
                                                    }
                                                >
                                                    Delivered
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={
                                                        () => this
                                                            .showChangeStatusModalCallback(
                                                                purchaseId,
                                                                statusBadgeText,
                                                                "On Hold",
                                                                purchase.customer_email,
                                                                purchase.customer_last_name,
                                                                purchase.customer_first_name,
                                                                "ON_HOLD"
                                                            )
                                                    }
                                                >
                                                    On Hold
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={
                                                        () => this
                                                            .showChangeStatusModalCallback(
                                                                purchaseId,
                                                                statusBadgeText,
                                                                "On Hold",
                                                                purchase.customer_email,
                                                                purchase.customer_last_name,
                                                                purchase.customer_first_name,
                                                                "REQUEST_MISSING_INFO",
                                                                true
                                                            )
                                                    }
                                                >
                                                    Ask Information
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onClick={
                                                        () => this
                                                            .showUploadDocumentModal(
                                                                purchaseId,
                                                                purchase.customer_last_name,
                                                                purchase.customer_first_name,
                                                                purchase,
                                                                true
                                                            )
                                                    }
                                                >
                                                    Upload
                                                    {
                                                        null !== purchase?.attachment_path &&
                                                        "" !== purchase?.attachment_path ?
                                                            <Badge
                                                                pill
                                                                variant="success"
                                                                className="mt-2 mr-2"
                                                            >
                                                                1
                                                            </Badge>
                                                            : null
                                                    }
                                                </Dropdown.Item>
                                            </>
                                            :
                                            undefined
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        </td>
                    </tr>;
                }
            );
    };

    /**
     * Perform the Meilisearch search operation using the provided filtered values.
     *
     * @returns {Promise<void>}
     */
    searchPurchases = async () => {
        await new Promise(resolve => setTimeout(resolve, 250)); // Sleep for 250ms before continue
        const convertDateToUnixTimestamp = (dateString, from = true) => {
            const dateObject = new Date(dateString);
            if (from) {
                dateObject.setHours(0, 0, 0, 0);
            } else {
                dateObject.setHours(23, 59, 59, 99);
            }
            const timestamp = dateObject.getTime() / 1000;
            return Math.floor(timestamp);
        };
        const tab                        = this.state.activeTab;
        const searchParams               = {
            filter: `${this.state.searchCriteria[tab].searchStatuses} AND service NOT IN [CLASSICA, 'CLASSICA +'] AND is_prospect = false`,
            sort  : [
                this.state.searchCriteria[tab].orderBy
            ],
            page  : parseInt(this.state.searchCriteria[tab].currentPage, 10)
        };

        if (
            "" !== this.state.searchCriteria[tab].searchField &&
            "" !== this.state.searchCriteria[tab].searchTerm
        ) {
            searchParams.q = `${this.state.searchCriteria[tab].searchTerm}`;
            if ("any_field" !== this.state.searchCriteria[tab].searchField) {
                searchParams.attributesToSearchOn = [
                    this.state.searchCriteria[tab].searchField
                ];
            }
        }

        const fromDateString = this.state.searchCriteria[tab].from;
        let fromTimestamp    = null;
        if ("" !== fromDateString) {
            fromTimestamp = convertDateToUnixTimestamp(fromDateString);
        }

        const toDateString = this.state.searchCriteria[tab].to;
        let toTimestamp    = null;
        if ("" !== toDateString) {
            toTimestamp = convertDateToUnixTimestamp(toDateString, false);
        }

        switch (true) {
            case null !== fromTimestamp && null === toTimestamp:
                searchParams.filter += ` AND subscription_started >= ${fromTimestamp}`;
                break;
            case null === fromTimestamp && null !== toTimestamp:
                searchParams.filter += ` AND subscription_started <= ${toTimestamp}`;
                break;
            case null !== fromTimestamp && null !== toTimestamp:
                searchParams.filter += ` AND subscription_started ${fromTimestamp} TO ${toTimestamp}`;
                break;
        }

        const service = this.state.searchCriteria[tab].service;
        const isArray = Array.isArray(service) && service[0] !== "" && service.length > 0;

        if (isArray) {
            if (service.length > 1) {
                const services = service.map(s => `'${s}'`).join(", ");
                searchParams.filter += ` AND service_slug IN [${services}]`;
            } else {
                searchParams.filter += ` AND service_slug = '${service[0]}'`;
            }
        }

        const purchaseType = this.state.searchCriteria[tab].purchaseType;
        if ("" !== purchaseType) {
            searchParams.filter += ` AND order_type = ${purchaseType}`;

            if (purchaseType === "SALES") {
                searchParams.sort[0] = (tab === "delivered") ? "purchase_updated:desc" : "subscription_started:asc";
            }

            if (purchaseType === "CONNECTED_AREA") {
                searchParams.sort[0] = (tab === "delivered") ? "purchase_updated:desc" : "purchase_created:asc";
            }

            if (purchaseType === "") {
                searchParams.sort[0] = "purchase_created:desc";
            }
        }

        const subscriptionStatus = this.state.searchCriteria[tab].subscriptionStatus;
        if ("" !== subscriptionStatus) {
            searchParams.filter += ` AND is_subscribed = ${subscriptionStatus}`;
        }

        let cookieValue = btoa(JSON.stringify(this.state.searchCriteria));
        let tabValue    = btoa(tab);
        JsCookie.set(
            // psfv = Purchase Search Form Values
            "psfv",
            `${cookieValue}=+=+=+=${tabValue}`,
            {
                expires: 1 / 24,
                path   : "/"
            }
        );

        setTimeout(
            async () => await this.props.meiliSearch(searchParams),
            parseInt(process.env.REACT_APP_MEILI_SEARCH_DELAY, 10)
        );
    };

    /**
     * Rendering the search form for the purchases.
     *
     * @returns {JSX.Element}
     */
    meiliSearchForm = () => {
        const tab = this.state.activeTab;

        const updateSearchFieldState = async key => {
            this
                .setState(
                    {
                        searchCriteria: {
                            ...this.state.searchCriteria,
                            [tab]: {
                                ...this.state.searchCriteria[tab],
                                ...{
                                    searchField: key,
                                    searchTerm : "",
                                    currentPage: 1
                                }
                            }
                        }
                    }
                );
            this.searchFieldList.current.value = "";
            this.searchTermField.current.focus();

            window.requestAnimationFrame(
                () => {
                    this
                        .setState(
                            {
                                searchCriteria: {
                                    ...this.state.searchCriteria,
                                    ...{
                                        [tab]: {
                                            ...this.state.searchCriteria[tab],
                                            searchFieldLabel: this
                                                .searchFields
                                                .filter(
                                                    field => field.key === this.state.searchCriteria[tab].searchField
                                                )[0].value
                                        }
                                    }
                                }
                            }
                        );
                }
            );

            window.requestAnimationFrame(
                async () => {
                    store.dispatch(resetMeiliPagination());
                    await this.searchPurchases();
                }
            );
        };
        const updateSearchTermState  = async searchTerm => {
            this.setState(
                {
                    searchCriteria: {
                        ...this.state.searchCriteria,
                        [tab]: {
                            ...this.state.searchCriteria[tab],
                            ...{
                                searchTerm : searchTerm.target.value,
                                currentPage: 1
                            }
                        }
                    }
                }
            );

            window.requestAnimationFrame(
                async () => {
                    store.dispatch(resetMeiliPagination());
                    await this.searchPurchases();
                }
            );

            return true;
        };

        return <Form className="mb-2">
            {this.pageAlerts()}

            <Row className="mb-1">
                <Col>
                    <InputGroup className="mb-3">
                        <DropdownButton
                            as={InputGroup.Prepend}
                            variant="outline-primary"
                            title={this.state.searchCriteria[tab].searchFieldLabel}
                            id="input-group-dropdown-1"
                            ref={this.searchFieldList}
                        >
                            {
                                this
                                    .searchFields
                                    .map(
                                        field => {
                                            return <Dropdown.Item
                                                eventKey={field.key}
                                                key={`field_${field.key}`}
                                                onSelect={key => updateSearchFieldState(key)}
                                            >
                                                {field.value}
                                            </Dropdown.Item>;
                                        }
                                    )
                            }
                        </DropdownButton>
                        <FormControl
                            as={"input"}
                            onKeyUp={e => updateSearchTermState(e)}
                            defaultValue={this.state.searchCriteria[tab].searchTerm}
                            ref={this.searchTermField}
                        />
                    </InputGroup>
                </Col>
            </Row>

            <Row className="mb-1">
                <Col>
                    <Form.Group controlId="filterForm.fromDate">
                        <Form.Control
                            type="date"
                            ref={this.fromDate}
                            onChange={
                                () => {
                                    this.resetPagination();
                                    store.dispatch(resetMeiliPagination());

                                    this.setState(
                                        {
                                            searchCriteria: {
                                                ...this.state.searchCriteria,
                                                [tab]: {
                                                    ...this.state.searchCriteria[tab],
                                                    ...{
                                                        from: this.fromDate.current.value
                                                    }
                                                }
                                            }
                                        }
                                    );

                                    window.requestAnimationFrame(async () => await this.searchPurchases());
                                }
                            }
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Control
                        type="date"
                        ref={this.toDate}
                        onChange={
                            () => {
                                this.resetPagination();
                                store.dispatch(resetMeiliPagination());

                                this.setState(
                                    {
                                        searchCriteria: {
                                            ...this.state.searchCriteria,
                                            [tab]: {
                                                ...this.state.searchCriteria[tab],
                                                ...{
                                                    to: this.toDate.current.value
                                                }
                                            }
                                        }
                                    }
                                );

                                window.requestAnimationFrame(async () => await this.searchPurchases());
                            }
                        }
                    />
                </Col>
                <Col>
                    <MultiSelect
                        servicesRef={this.serviceName}
                        serviceData={this.props.services}
                        onChangeSelect={(selectedOptions) => {
                            this.resetPagination();
                            store.dispatch(resetMeiliPagination());

                                if (this.serviceName.current) {

                                    this.setState({
                                        searchCriteria: {
                                            ...this.state.searchCriteria,
                                            [tab]: {
                                                ...this.state.searchCriteria[tab],
                                                service: selectedOptions
                                            }
                                        }
                                    });
                                    window.requestAnimationFrame(async () => await this.searchPurchases());
                                }
                            }
                        }
                        tabIndex={tab}
                    />
                    {null !== this.props.serviceListError && <Form.Text
                        id="servicesHelpBlock"
                        className="text-danger"
                    >
                        {this.props.serviceListError}
                    </Form.Text>}
                </Col>
                <Col>
                    <Form.Control
                        as="select"
                        ref={this.purchaseType}
                        onChange={
                            () => {
                                this.resetPagination();
                                store.dispatch(resetMeiliPagination());

                                this.setState(
                                    {
                                        searchCriteria: {
                                            ...this.state.searchCriteria,
                                            [tab]: {
                                                ...this.state.searchCriteria[tab],
                                                ...{
                                                    purchaseType: this.purchaseType.current.value
                                                }
                                            }
                                        }
                                    }
                                );

                                window.requestAnimationFrame(async () => await this.searchPurchases());
                            }
                        }
                    >
                        <option value="">Type</option>
                        <option value="SALES">Sale</option>
                        <option value="CONNECTED_AREA">Subscriber</option>
                    </Form.Control>
                </Col>
                <Col>
                    <Form.Control
                        as="select"
                        ref={this.subscriptionStatusField}
                        onChange={
                            () => {
                                this.resetPagination();
                                store.dispatch(resetMeiliPagination());

                                this.setState(
                                    {
                                        searchCriteria: {
                                            ...this.state.searchCriteria,
                                            [tab]: {
                                                ...this.state.searchCriteria[tab],
                                                ...{
                                                    subscriptionStatus: this.subscriptionStatusField.current.value
                                                }
                                            }
                                        }
                                    }
                                );

                                window.requestAnimationFrame(async () => await this.searchPurchases());
                            }
                        }
                    >
                        <option value="">Subscription Status</option>
                        <option value="true">Active</option>
                        <option value="false">Inactive</option>
                    </Form.Control>
                </Col>
                <Col xs={"auto"}>
                    <ButtonGroup size="sm" aria-label="Form Action Buttons">
                        <Button
                            variant="dark"
                            onClick={this.resetFormFiltersMethod}
                        >
                            <Icon.Delete
                                className="icon"
                            />
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
        </Form>;
    };

    /**
     * Display eny alerts that may rise in the purchases table.
     *
     * @returns {JSX.Element}
     */
    pageAlerts() {
        return <>
            {
                this.props.uploadSuccess === true &&
                this.props.uploadError === null ?
                    <Alert key={"successUpload"} variant={"success"}>
                        Success !! your document has been uploaded.
                    </Alert> :
                    null
            }
            {
                this.props.uploadError !== null ?
                    <Alert key={"dangerUpload"} variant={"danger"}>
                        Upload failed please re try.
                    </Alert> :
                    null
            }
            {
                this.props.deleteSuccess === true &&
                this.props.deleteError === null ?
                    <Alert key={"successDelete"} variant={"success"}>
                        Success !! your document has been deleted.
                    </Alert> :
                    null
            }
            {
                this.props.deleteError !== null ?
                    <Alert key={"dangerDelete"} variant={"danger"}>
                        delete was failed failed please re try.
                    </Alert> :
                    null
            }
            {
                this.props.isCopied === true ?
                    <Alert key={"successCopied"} variant={"success"}>
                        Copy to clipboard completed.
                    </Alert> :
                    null
            }
            {
                this.state.drivingLicensePointsNotValid === true ?
                    <Alert key={"dangerPoints"} variant={"danger"}>
                        Please select a number of points under or equal 30.
                    </Alert> :
                    null
            }
            {
                this.props.purchases.purchasesListError !== null ?
                    <Alert variant="danger" className="rounded">
                        {this.props.purchases.purchasesListError}
                    </Alert> :
                    undefined
            }
            {
                this.props.uploadAndDeliveredSuccess === true ?
                    <Alert key={"successDelivered"} variant={"success"}>
                        Success !! your purchase status has been upload and updated.
                    </Alert> :
                    null
            }
            {
                this.props.uploadAndDeliveredError !== null ?
                    <Alert key={"dangerDelivered"} variant={"danger"}>
                        Upload and update status failed please re try.
                    </Alert> :
                    null
            }
        </>;
    }

    /**
     * Mark a purchases as Delivered and refresh the search results.
     *
     * @returns {Promise<void>}
     */
    toDeliveredBulkButton = async () => {
        const tab = this.state.activeTab;

        await this
            .props
            .setStatusToCompleted(
                this.state.searchCriteria[tab].selectedFieldsIds[0],
                this.state.searchCriteria[tab].selectedFieldsIds
            );

        // We need to give time to Meili search update the index before we
        // fetch the updated values.
        setTimeout(
            async () => {
                await this
                    .props
                    .getTotalPendingInProgressTransactions();
                await this
                    .props
                    .getTotalOnHoldTransactions();
                await this
                    .props
                    .getTotalAskInformationTransactions();
            },
            2000
        );

        window
            .requestAnimationFrame(
                async () => {
                    this.setState(
                        {
                            searchCriteria: {
                                ...this.state.searchCriteria,
                                [tab]: {
                                    ...this.state.searchCriteria[tab],
                                    ...{
                                        currentPage      : 1,
                                        selectedFieldsIds: []
                                    }
                                }
                            }
                        }
                    );

                    // Give some time to Meili Search to Index the requested changes and the fetch the
                    // purchases. If we call immediatly the fetch, the Meili will respond with the old data.
                    setTimeout(
                        async () => await this.searchPurchases(),
                        2000
                    );
                }
            );
        this.setState({showBulkActionModalState: false});
    };

    /**
     * Mark a purchases as On Hold and refresh the search results.
     *
     * @returns {Promise<void>}
     */
    toOnHoldBulkButton = async () => {
        const tab = this.state.activeTab;

        await this
            .props
            .setStatusToOnHold(
                this.state.searchCriteria[tab].selectedFieldsIds[0],
                this.state.searchCriteria[tab].selectedFieldsIds
            );

        // We need to give time to Meili search update the index before we
        // fetch the updated values.
        setTimeout(
            async () => {
                await this
                    .props
                    .getTotalPendingInProgressTransactions();
                await this
                    .props
                    .getTotalOnHoldTransactions();
                await this
                    .props
                    .getTotalAskInformationTransactions();
            },
            2000
        );

        window
            .requestAnimationFrame(
                async () => {
                    this.setState(
                        {
                            searchCriteria: {
                                ...this.state.searchCriteria,
                                [tab]: {
                                    ...this.state.searchCriteria[tab],
                                    ...{
                                        currentPage      : 1,
                                        selectedFieldsIds: []
                                    }
                                }
                            }
                        }
                    );

                    // Give some time to Meili Search to Index the requested changes and the fetch the
                    // purchases. If we call immediatly the fetch, the Meili will respond with the old data.
                    setTimeout(
                        async () => await this.searchPurchases(),
                        2000
                    );
                }
            );
        this.setState({showBulkActionModalState: false});
    };

    /**
     * Mark the given purchases as Ask Information and refresh the search results.
     *
     * @returns {Promise<void>}
     *
     toAskInformationBulkButton = async () => {
     const tab = this.state.activeTab;

     await this
     .props
     .requestMissingInformation(
     this.state.searchCriteria[tab].selectedFieldsIds[0],
     this.state.searchCriteria[tab].selectedFieldsIds
     );

     // We need to give time to Meili search update the index before we
     // fetch the updated values.
     setTimeout(
     async () => {
     await this
     .props
     .getTotalPendingInProgressTransactions();
     await this
     .props
     .getTotalOnHoldTransactions();
     },
     2000
     );

     window.requestAnimationFrame(
     async () => {
     this.setState(
     {
     searchCriteria: {
     ...this.state.searchCriteria,
     [tab]: {
     ...this.state.searchCriteria[tab],
     ...{
     currentPage      : 1,
     selectedFieldsIds: []
     }
     }
     }
     }
     );

     // Give some time to Meili Search to Index the requested changes and the fetch the
     // purchases. If we call immediatly the fetch, the Meili will respond with the old data.
     setTimeout(
     async () => await this.searchPurchases(),
     2000
     );
     }
     );
     };*/

    /**
     * Save in the redux store the confirmation comment.
     *
     * @param e
     */
    handleCommentsText = (e) => {
        store
            .dispatch(
                setConfirmationComments(e.target.value)
            );
    };

    /**
     * Toggle the comments section in the dialogs.
     */
    showCommentsSection = () => {
        this
            .setState(
                {
                    setShowComments: !this.state.setShowComments
                }
            );
    };

    purchasesUI = () => {
        const tab = this.state.activeTab;

        return <>
            {this.meiliSearchForm()}

            <Table className="m-0" responsive>
                <thead>
                <tr key="table_headers">
                    <th>Email</th>
                    <th width={"270"}>Descriptor</th>
                    <th width={"250px"}>Date</th>
                    <th width={"170px"}>Product</th>
                    <th width={"95px"}>Status</th>
                    <th width={"80px"}>Type</th>
                    <th width={"100px"}>Subscription Status</th>
                    <th width={"120px"}>Actions</th>
                </tr>
                </thead>
                <tbody>
                {
                    this.props.purchases.meiliPurchases.length === 0 ?
                        <tr key="no_purchases">
                            <td colSpan={8} className="text-center">No Purchases Yet</td>
                        </tr> :
                        this.mapMeiliPurchases()
                }
                </tbody>
            </Table>

            <Paginator
                current={this.state.searchCriteria[tab].currentPage}
                first={this.props.purchases.meiliPurchasesFirstPage}
                last={this.props.purchases.meiliPurchasesLastPage}
                next={this.props.purchases.meiliPurchasesNextPage}
                prev={this.props.purchases.meiliPurchasesPrevPage}
                onClickFirst={e => this.getPageToGoMethod(e)}
            />
        </>;
    };

    /**
     * Update the current active tab.
     *
     * @param status
     */
    changePurchaseStatus = status => {
        switch (status) {
            case "pending":
                this.setState(
                    {
                        activeTab     : "pending",
                        searchStatuses: "status IN [IN_PROGRESS, PENDING, CANCELLED_ORDER]",
                        orderBy       : "subscription_started:desc"
                    }
                );

                if (this.serviceName && this.serviceName.current) {
                    this.serviceName.current.value = this.state.searchCriteria[status].service;
                }

                this.searchFieldList.current.value         = this.state.searchCriteria[status].searchField;
                this.searchTermField.current.value         = this.state.searchCriteria[status].searchTerm;
                this.fromDate.current.value                = this.state.searchCriteria[status].from;
                this.toDate.current.value                  = this.state.searchCriteria[status].to;
                this.subscriptionStatusField.current.value = this.state.searchCriteria[status].subscriptionStatus;
                this.purchaseType.current.value            = this.state.searchCriteria[status].purchaseType;

                break;
            case "hold":
                this.setState(
                    {
                        activeTab     : "hold",
                        searchStatuses: "status IN [ON_HOLD]",
                        orderBy       : "subscription_started:asc"
                    }
                );

                if (this.serviceName && this.serviceName.current) {
                    this.serviceName.current.value = this.state.searchCriteria[status].service;
                }

                this.searchFieldList.current.value         = this.state.searchCriteria[status].searchField;
                this.searchTermField.current.value         = this.state.searchCriteria[status].searchTerm;
                this.fromDate.current.value                = this.state.searchCriteria[status].from;
                this.toDate.current.value                  = this.state.searchCriteria[status].to;
                this.subscriptionStatusField.current.value = this.state.searchCriteria[status].subscriptionStatus;
                this.purchaseType.current.value            = this.state.searchCriteria[status].purchaseType;
                break;
            case "askInformation":
                this.setState(
                    {
                        activeTab     : "askInformation",
                        searchStatuses: "status IN [ASK_INFORMATION]",
                        orderBy       : "purchase_created:asc"
                    }
                );

                if (this.serviceName && this.serviceName.current) {
                    this.serviceName.current.value = this.state.searchCriteria[status].service;
                }

                this.searchFieldList.current.value         = this.state.searchCriteria[status].searchField;
                this.searchTermField.current.value         = this.state.searchCriteria[status].searchTerm;
                this.fromDate.current.value                = this.state.searchCriteria[status].from;
                this.toDate.current.value                  = this.state.searchCriteria[status].to;
                this.subscriptionStatusField.current.value = this.state.searchCriteria[status].subscriptionStatus;
                this.purchaseType.current.value            = this.state.searchCriteria[status].purchaseType;
                break;
            case "delivered":
                this.setState(
                    {
                        activeTab     : "delivered",
                        searchStatuses: "status IN [COMPLETED, CANCELLED]",
                        orderBy       : "purchase_updated:desc"
                    }
                );

                if (this.serviceName && this.serviceName.current) {
                    this.serviceName.current.value = this.state.searchCriteria[status].service;
                }

                this.searchFieldList.current.value         = this.state.searchCriteria[status].searchField;
                this.searchTermField.current.value         = this.state.searchCriteria[status].searchTerm;
                this.fromDate.current.value                = this.state.searchCriteria[status].from;
                this.toDate.current.value                  = this.state.searchCriteria[status].to;
                this.subscriptionStatusField.current.value = this.state.searchCriteria[status].subscriptionStatus;
                this.purchaseType.current.value            = this.state.searchCriteria[status].purchaseType;
                break;
        }

        window.requestAnimationFrame(() => this.searchPurchases());
    };

    getPurchasesMissingFilesCount = () => {
        const tab = this.state.activeTab;

        return this
            .props
            .purchases
            .meiliPurchases
            .filter(
                purchase => {
                    return -1 !== this.state.searchCriteria[tab].selectedFieldsIds.indexOf(purchase.id) && "" === purchase.attachment_path;
                }
            ).length;
    }

    render() {
        const tab  = this.state.activeTab;
        let loader = null;

        if (
            this.props.loadingPurchasesList ||
            this.props.loadingProductsList ||
            this.props.loadingEmailTemplate
        ) {
            loader = <Loader message="Loading..." />;
        }

        let pendingInProgressTransactionBadge = undefined;
        let onHoldTransactionBadge            = undefined;
        let askInformationTransactionBadge    = undefined;

        if (this.props.totalPendingInProgressTransactions !== 0) {
            pendingInProgressTransactionBadge = <>
                <Badge
                    variant="primary"
                    pill
                    className="badgeSmall ml-1"
                    data-tip="Pending or In Progress transactions"
                >
                    {this.props.totalPendingInProgressTransactions}
                </Badge>
                <ReactTooltip />
            </>;
        }

        if (this.props.totalOnHoldTransactions !== 0) {
            onHoldTransactionBadge = <>
                <Badge
                    variant="secondary"
                    pill
                    className="badgeSmall ml-1"
                    data-tip="Pending or In Progress transactions"
                >
                    {this.props.totalOnHoldTransactions}
                </Badge>
                <ReactTooltip />
            </>;
        }

        if (this.props.totalAskInformationTransactions !== 0) {
            askInformationTransactionBadge = <>
                <Badge
                    variant="danger"
                    pill
                    className="badgeSmall ml-1"
                    data-tip="Ask Information transactions"
                >
                    {this.props.totalAskInformationTransactions}
                </Badge>
                <ReactTooltip />
            </>;
        }

        return <>
            <div className="page-wrapper">
                {/* Navigation */}
                <Navigation onClick={this._onSideMenu} />
                {/* End Navigation */}

                <div className={`main-content d-flex flex-column ${this.state.sideMenu ? "" : "hide-sidemenu"}`}>
                    {/* Loader */}
                    {loader}
                    {/* End Loader */}

                    {/* Breadcrumb */}
                    <div className="main-content-header">
                        <Breadcrumb>
                            <h1>Purchases</h1>
                            <Link to={routerConstants.dashboard} className="breadcrumb-item">
                                Dashboard
                            </Link>
                            <Breadcrumb.Item active>
                                Purchases
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    {/* End Breadcrumb */}

                    <ConfirmStatusChangeModal
                        show={this.state.showStatusChangeModal}
                        processing={this.state.processingStatusChange}
                        onClose={this.hideChangeStatusModalCallback}
                        onConfirm={this.setStatusToCancelledMethod}
                        purchaseId={this.state.purchaseId}
                        currentStatusText={this.state.currentStatusText}
                        nextStatusText={this.state.nextStatusText}
                        customerEmail={this.state.customerEmail}
                        customerLastName={this?.state?.customerLastName ?? ""}
                        customerFirstName={this?.state?.customerFirstName ?? ""}
                        requestMissingInfo={this.state.missingInformation}
                        emailTemplateValue={this.state.emailTemplateData}
                        emailTemplateOption={this.state.emailTemplate}
                        emailTemplateOnChange={this.onEmailTemplateChange}
                        nameOfService={this.state.nameOfService}
                        onUpdateDrivingLicensePoints={this.updateDrivingLicensePointsCallback}
                        showCommentsSection={this.showCommentsSection}
                        handleCommentsText={this.handleCommentsText}
                        commentsText={this.props.commentsText}
                    />

                    <UploadDocumentModal
                        show={this.props.showUploadDocumentModal}
                        customerLastName={this.state.customerLastName}
                        customerFirstName={this.state.customerFirstName}
                        purchaseId={this.state.purchaseId}
                        purchase={this.state.purchase}
                        onClose={this.hideUploadDocumentModal}
                        purchaseType={"PENDING_IN_PROGRESS_PURCHASE"}
                        currentPage={this.state.searchCriteria[tab].currentPage}
                        getPurchase={this.searchPurchases}
                        handleCommentsText={this.handleCommentsText}
                        commentsText={this.props.commentsText}
                    />

                    <ConfirmBulkActionModal
                        show={this.state.showBulkActionModalState}
                        onClose={this.hideBulkActionModal}
                        purchaseStatus={this.state.purchaseStatusModal}
                        onConfirm={this.state.purchaseStatusModal === "hold" ? this.toOnHoldBulkButton : this.toDeliveredBulkButton}
                        currentAction={this.state.bulkAction}
                        missingFiles={this.getPurchasesMissingFilesCount()}
                    />

                    <Row>
                        <Col lg={12}>
                            <div className="card mb-4">
                                <div className="card-body">

                                    <div className="card-header d-flex">
                                        <h5 className="card-title w-50 float-left">Purchases</h5>
                                    </div>

                                    <div className="tabs-style-three">
                                        <Tabs
                                            className="fakeTab"
                                            defaultActiveKey={this.state.activeTab}
                                            activeKey={this.state.activeTab}
                                            onSelect={activeKey => this.changePurchaseStatus(activeKey)}
                                        >
                                            <Tab
                                                eventKey="pending"
                                                id="pendingTab"
                                                title={
                                                    <>Pending &amp; In
                                                        Progress&nbsp;{pendingInProgressTransactionBadge}</>
                                                }
                                            />
                                            <Tab
                                                eventKey="hold"
                                                id="holdTab"
                                                title={
                                                    <>On Hold&nbsp;{onHoldTransactionBadge}</>
                                                }
                                            />
                                            <Tab
                                                eventKey="askInformation"
                                                id="askInformationTab"
                                                title={
                                                    <>Ask Information&nbsp;{askInformationTransactionBadge}</>
                                                }
                                            />
                                            <Tab
                                                eventKey="delivered"
                                                id="deliveredTab"
                                                title="Delivered"
                                            />
                                        </Tabs>

                                        <div
                                            className="tabContainer"
                                            style={{padding: "20px", border: "1px solid #dee2e6", borderTop: "none"}}
                                        >
                                            {this.purchasesUI()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {
                        1 > this.state.searchCriteria[tab].selectedFieldsIds.length &&
                        <div>
                            <Button

                                type="button"
                                className="mr-1"
                                variant="danger"
                                onClick={this.selectAllPurchases}
                            >
                                Select All
                            </Button>
                        </div>
                    }
                    {
                        0 < this.state.searchCriteria[tab].selectedFieldsIds.length &&
                        <div>
                            {
                                "hold" !== this.state.activeTab &&
                                <Button
                                    onClick={() => this.showBulkActionModal("hold")}
                                    type="button"
                                    className="mr-1"
                                >
                                    On Hold
                                </Button>
                            }
                            {
                                "delivered" !== this.state.activeTab &&
                                <Button
                                    onClick={() => this.showBulkActionModal("delivered", "delivered")}
                                    type="button"
                                    className="mr-1"
                                    variant="warning"
                                >
                                    Delivered
                                </Button>
                            }
                            {
                                <Button
                                    type="button"
                                    className="mr-1"
                                    variant="danger"
                                    onClick={this.unselectAllPurchases}
                                >
                                    Unselect All
                                </Button>
                            }
                        </div>
                    }
                    {/* Footer */}
                    <div className="flex-grow-1" />
                    <Footer />
                    {/* End Footer */}
                </div>
            </div>
        </>;
    }
}

const mapStateToProps = state => {
    return {
        purchases                         : state.purchases,
        loadingPurchasesList              : state.purchases.loadingPurchasesList,
        services                          : state.services.servicesList,
        serviceListError                  : state.services.serviceListError,
        loadingServicesList               : state.services.loadingServicesList,
        uploadSuccess                     : state.purchases.purchasesUploadedDocumentSuccess,
        uploadError                       : state.purchases.purchasesUploadedDocumentError,
        deleteError                       : state.purchases.deleteDocumentError,
        deleteSuccess                     : state.purchases.deleteDocumentSuccess,
        showUploadDocumentModal           : state.purchases.showUploadDocumentModal,
        iconDocumentUploaded              : state.purchases.iconDocumentUploaded,
        isCopied                          : state.purchases.isTextCopied,
        totalPendingInProgressTransactions: state.purchases.totalPendingInProgressTransactions,
        totalOnHoldTransactions           : state.purchases.totalOnHoldTransactions,
        totalAskInformationTransactions   : state.purchases.totalAskInformationTransactions,
        emailTemplate                     : state.emailTemplates.emailTemplateList,
        errorEmailTempltate               : state.emailTemplates.errorEmailTemplate,
        emailTemplateLoading              : state.emailTemplates.loadingEmailTemplate,
        searchBarValue                    : state.globalState.filterSearchBarValuePendingPage,
        hideComments                      : state.purchases.hideComments,
        commentsText                      : state.purchases.modalConfirmationComments,
        uploadAndDeliveredSuccess         : state.purchases.uploadAndMarkAsDeliveredSuccess,
        uploadAndDeliveredError           : state.purchases.uploadAndMarkAsDeliveredError,
    };
};

const mapActionsToProps = {
    getServicesList,
    setStatusToCompleted,
    setStatusToCancelled,
    setStatusToOnHold,
    setPurchaseDrivingLicensePoints,
    getTotalPendingInProgressTransactions,
    getTotalAskInformationTransactions,
    getTotalOnHoldTransactions,
    requestMissingInformation,
    getEmailTemplatesList,
    meiliSearch,
    resetMeiliPagination
};

const ListPurchasesConnected = connect(
    mapStateToProps,
    mapActionsToProps
)(ListPurchases);

export default withRouter(ListPurchasesConnected);
